import UploadForm from "../../../components/UploadForm/UploadForm"
import { useState, useEffect } from 'react'
import EditableTable from "../../../components/Tables/EditableTable"
import {
    postSingleInfraSoftware,
    uploadInfraSoftware,
    getInfraSoftware,
    deleteSingleInfraSoftware,
    putSingleInfraSoftware,
    endOfLifeGenerate
} from "../../../services/CloudMigrationAdvisor/InfrastructureAssessment"
import { useMyGlobalContext } from "../../../global/globalContext"
import toast from "react-hot-toast"

const InfrAssessmentSoftwareDataIngestion = () => {
    const { globalState } = useMyGlobalContext()
    const [tableData, setTableData] = useState([])
    const [indexIdMapping, setIndexIdMapping] = useState({})

    const init = async () => {
        try {
            const response = await getInfraSoftware(globalState.projectId, globalState.assessmentId)
            const data = response.data
            let tempMapping = {}
            setTableData(
                data.map((singleSoftwareData, index) => {
                    tempMapping[index] = singleSoftwareData.id
                    return [
                        singleSoftwareData.Name,
                        singleSoftwareData.Type,
                        singleSoftwareData.Version,
                        singleSoftwareData.Descrption,
                        singleSoftwareData.Number_of_Installations,
                        singleSoftwareData.FormalName,
                        singleSoftwareData.FormalVersion,
                        singleSoftwareData.End_of_Support_Date
                    ]
                })
            )
            setIndexIdMapping(tempMapping)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        init()
    }, [])

    const [headers, setHeaders] = useState([
        "Name",
        "Type",
        "Version",
        "Description",
        "#Installations",
        "Formal Name",
        "Formal Version",
        "End of Support Date",
    ])

    const handleUpload = async (formData) => {
        try {
            await uploadInfraSoftware(globalState.projectId, globalState.assessmentId, formData)
            toast.success("File uploaded successfully!")
        } catch (error) {
            toast.error("File upload failed")
        }
    }

    const handleSingleDelete = async (index) => {
        try {
            if (indexIdMapping[index]) {
                await deleteSingleInfraSoftware(globalState.projectId, globalState.assessmentId, indexIdMapping[index])
                init()
            } else {
                setTableData([...tableData.slice(0, index), ...tableData.slice(index + 1)])
            }
            toast.success("Software deleted successfully!")
        } catch (error) {
            toast.error("Software deletion failed")
        }
    }

    const handleSingleSave = async (index, rowData) => {
        try {
            if (indexIdMapping[index]) {
                const body = {
                    Name: rowData[0],
                    Type: rowData[1],
                    Version: rowData[2],
                    Descrption: rowData[3],
                    Number_of_Installations: rowData[4],
                    FormalName: rowData[5],
                    FormalVersion: rowData[6],
                    End_of_Support_Date: rowData[7],
                }
                await putSingleInfraSoftware(globalState.projectId, globalState.assessmentId, indexIdMapping[index], body)
            } else {
                const body = {
                    Name: rowData[0],
                    Type: rowData[1],
                    Version: rowData[2],
                    Descrption: rowData[3],
                    Number_of_Installations: rowData[4],
                    FormalName: rowData[5],
                    FormalVersion: rowData[6],
                    End_of_Support_Date: rowData[7],
                }
                await postSingleInfraSoftware(globalState.projectId, globalState.assessmentId, body)
            }
            init()
            toast.success("Software saved successfully!")
        } catch (error) {
            toast.error("Software save failed")
        }
    }

    const handleSoftwareGenerate = async () => {
        try {
            await endOfLifeGenerate(globalState.projectId, globalState.assessmentId)
            toast.success("Software end of life calculation started!")
        } catch (error) {
            toast.error("Software  end of life calculation failed")
        }
    }

    return (
        <div>
            <UploadForm
                downloadable={true}
                downloadUrl={`${process.env.REACT_APP_SERVER_URL}/templates/download/Count_of_software_template/`}
                confirmUpload={false}
                showFiles={false}
                handleUpload={handleUpload}
            />
            <button className='assessment-button' onClick={() => handleSoftwareGenerate()}>
                Calculate
            </button>
            {
                <EditableTable
                    headers={headers}
                    data={tableData}
                    setData={setTableData}
                    saveFlag={true}
                    deleteFlag={true}
                    deleteFunction={(index) => handleSingleDelete(index)}
                    saveFunction={(index, rowData) => handleSingleSave(index, rowData)}
                    addRowFlag={true}
                />}
        </div>
    )
}

export default InfrAssessmentSoftwareDataIngestion