import { useState, useEffect } from 'react'
import UploadForm from '../../../components/UploadForm/UploadForm'
import AssessmentList from '../../../components/Forms/AssessmentList'
import {  generateInfraQuestions } from "../../../services/CloudMigrationAdvisor/InfrastructureAssessment"
import { getContextQuestions, putContextQuestion, deleteContextQuestion } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { useMyGlobalContext } from "../../../global/globalContext"
import toast from 'react-hot-toast'

const InfrAssessmentQuestions = () => {
    const [furtherAssessmentData, setFurtherAssessmentData] = useState([]);
    const { globalState } = useMyGlobalContext()

    const initQuestions = async () => {
        try {
            const response = await getContextQuestions(globalState.projectId, globalState.assessmentId)
            const data = response.data
            const assessment = [
                {
                    category: 'Infrastructure Questions',
                    elements: data.filter(singeData => singeData.Type == "infrastructure context").map(singleData => ({
                        title: singleData.Question,
                        value: singleData.Answer,
                        ...singleData
                    }))
                }
            ]
            setFurtherAssessmentData(assessment)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        initQuestions()
    }, [])

    const handleSaveQuestions = async () => {
        try {
            let i = 0;
            for (i; i < furtherAssessmentData[0].elements.length; i++) {
                const element = furtherAssessmentData[0].elements[i]
                await putContextQuestion(globalState.projectId, globalState.assessmentId, element.id, {
                    "Name": element.Name,
                    "Question": element.Question,
                    "Answer": element.value,
                    "Type": element.Type
                })
            }
            toast.success("Infrastructure questions saved successfully!")
        } catch (error) {
            toast.error("Infrastructure questions saving failed")
        }
    }

    const generateQuestions = async () => {
        try {
            await generateInfraQuestions(globalState.projectId, globalState.assessmentId)
            toast.success("Infrastructure questions generation started!")
        } catch (error) {
            toast.error("Infrastructure questions generated start failed")
        }
    }

    const handleQuestionDelete = async (id) => {
        try {
            await deleteContextQuestion(globalState.projectId, globalState.assessmentId, id)
            initQuestions()
            toast.success("Infrastructure question deleted successfully!")
        } catch (error) {
            toast.error("Infrastructure question deletion failed")
        }
    }

    return (
        <>
            <UploadForm />
            <button className='assessment-button' onClick={() => generateQuestions()}>
                Generate
            </button>
            {<AssessmentList
                data={furtherAssessmentData}
                setData={setFurtherAssessmentData}
                generable={false}
                deletable={true}
                handleElementDelete={(id) => handleQuestionDelete(id)}
            />}
            <button className='assessment-button' style={{float:"right"}} onClick={() => handleSaveQuestions()}>
                Save
            </button>
        </>
    )
}

export default InfrAssessmentQuestions