// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rejectButton{
     background-color: white !important;
    color: #26890d !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.rejectButton:hover{
    background-color: lightgrey !important;
}

.confirmButton{

    background-color: #26890d !important;
    border-color: black !important;

    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);


}


.confirmButton:hover{
    background-color: #43b02a !important;
     color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ConfirmModal/ConfirmModal.css"],"names":[],"mappings":"AAAA;KACK,kCAAkC;IACnC,yBAAyB;IACzB,0CAA0C;AAC9C;AACA;IACI,sCAAsC;AAC1C;;AAEA;;IAEI,oCAAoC;IACpC,8BAA8B;;IAE9B,0CAA0C;;;AAG9C;;;AAGA;IACI,oCAAoC;KACnC,sBAAsB;AAC3B","sourcesContent":[".rejectButton{\n     background-color: white !important;\n    color: #26890d !important;\n    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);\n}\n.rejectButton:hover{\n    background-color: lightgrey !important;\n}\n\n.confirmButton{\n\n    background-color: #26890d !important;\n    border-color: black !important;\n\n    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);\n\n\n}\n\n\n.confirmButton:hover{\n    background-color: #43b02a !important;\n     color: #000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
