// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-wrapper {
    width: 90%;
    margin: auto;
}

.assessment-menu-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
}

.menu-item {
    width: 30%;
    margin: 0rem 0.2rem;
    color: #53565A;
    background-color: #BBBCBC;
    border-radius: 10px;
    padding: 0.15rem 0.5rem;
    border-style: solid;
    border-color: #BBBCBC;
    text-align: center;
}

.menu-item:hover {
    cursor: pointer;
}

.menu-item.selected {
    color: black;
    background-color: #86BC25;
    border-color: #86BC25;
    border-style: solid;
}`, "",{"version":3,"sources":["webpack://./src/components/AssessmentMenu/AssessmentMenu.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;IACrB,mBAAmB;AACvB","sourcesContent":[".menu-wrapper {\n    width: 90%;\n    margin: auto;\n}\n\n.assessment-menu-row {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0.2rem;\n}\n\n.menu-item {\n    width: 30%;\n    margin: 0rem 0.2rem;\n    color: #53565A;\n    background-color: #BBBCBC;\n    border-radius: 10px;\n    padding: 0.15rem 0.5rem;\n    border-style: solid;\n    border-color: #BBBCBC;\n    text-align: center;\n}\n\n.menu-item:hover {\n    cursor: pointer;\n}\n\n.menu-item.selected {\n    color: black;\n    background-color: #86BC25;\n    border-color: #86BC25;\n    border-style: solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
