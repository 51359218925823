import "./InfrAssessmentSoftwareObsolescence.css"
import { useState, useEffect } from 'react'
// import Slider from "rc-slider"
import { generateSoftwareObsol,getInfraSoftware, generateMigrateCriticality } from "../../../services/CloudMigrationAdvisor/InfrastructureAssessment"
import { getSummary, putSummary } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import 'rc-slider/assets/index.css';
import { useMyGlobalContext } from "../../../global/globalContext";
import RobotPopover from "../../../components/Popovers/RobotPopover";
import EditableTable from "../../../components/Tables/EditableTable";
import toast from "react-hot-toast";

const InfrAssessmentSoftwareObsolescence = () => {
    const [summaryData, setSummaryData] = useState()
    const [softwareData, setSoftwareData] = useState([])
    const [softwareIds, setSoftwareIds] = useState([])
    const [softwareDataHeaders, setSoftwareDataHeaders] = useState([
        "Name",
        "Type",
        "Version",
        "Migration Criticality"
    ])

    const { globalState } = useMyGlobalContext()
    const init = async () => {
        try {
            try {
                const response = await getSummary(globalState.projectId, globalState.assessmentId)
                const data = response.data
                setSummaryData(data)
            } catch (error) {
                console.log(error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const initSoftwareData = async() =>{
        try{
            const response = await getInfraSoftware(globalState.projectId, globalState.assessmentId)
            const data = response.data
            setSoftwareData(data.map(singleData=>([
                singleData.Name,
                singleData.Type,
                singleData.Version,
                singleData.Migration_criticality
            ])))
            setSoftwareIds(data.map(singleData=>([
                singleData.id,
            ])))
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        init()
        initSoftwareData()
    }, [])

    const handleGenerate = async () => {
        try {
            await generateSoftwareObsol(globalState.projectId, globalState.assessmentId)
            toast.success("Software Obsolescence generation started!")
        } catch (error) {
            toast.error("Software Obsolescence generation failed")
        }
    }

    const handleGenerateMigrationCrit = async () => {
        try{
            let i = 0;
            for(i=0;i<softwareIds.length;i++){
                await generateMigrateCriticality(globalState.projectId, globalState.assessmentId, softwareIds[i])
            }
            initSoftwareData()
            toast.success("Software Migration Criticality started!")
        } catch (error) {
            toast.error("Software Migration Criticality failed")
        }
    }

    return (
        <div className="soft-obs-wrapper">
            <div>
                <div style={{ justifyContent: "center", display: "flex", height: "400px", width: "100%" }}>
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/softwareeol/`}
                        alt="server os graph"
                        style={{ width: "600px" }}
                    />
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/softwareeolinstallations/`}
                        alt="server os pie"
                        style={{ width: "600px" }}
                    />
                </div>
                <div style={{ justifyContent: "center", display: "flex", height: "400px", width: "100%" }}>
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/softwaretype/`}
                        alt="server os graph"
                        style={{ width: "600px" }}
                    />
                </div>
                <button className='assessment-button' onClick={() => handleGenerateMigrationCrit()}>Generate Migration Criticality</button>
                <EditableTable
                    headers={softwareDataHeaders}
                    data={softwareData}
                    setData={setSoftwareData}
                    textAreaColumn={3}
                />
                <button className='assessment-button' onClick={() => handleGenerate()}>Generate</button>
                <RobotPopover
                    text={summaryData?.SoftwareObsolence_Analysis_Consideration ? summaryData.SoftwareObsolence_Analysis_Consideration : ""}
                    position="right"
                />
            </div>
        </div>
    )
}

export default InfrAssessmentSoftwareObsolescence