// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-info-text {
  width: 600px !important;
  margin-top: -4px !important;
}

.project-info-text:hover {
  border-color: green;
}

.cancel-btn {
  margin-left: 30px;
  background-color: white !important;
  color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.cancel-btn:hover {
  background-color: lightgrey !important;
}

.create-btn {
  margin-left: 10px;
  background-color: green !important;
  border-color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.create-btn:hover {
  background-color: green !important;
  opacity: 85%;
}

.createProjectForm {
  margin-left: 15px;
}

.newProjectHr {
  width: 600px;
}

.newProject {
  margin-top: 10px;
  font-size: 27px;
  margin-left: 50px;
  width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApplicationBuild/ApplicationCreateProject.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,uBAAuB;EACvB,0CAA0C;AAC5C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,8BAA8B;EAC9B,0CAA0C;AAC5C;;AAEA;EACE,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".project-info-text {\n  width: 600px !important;\n  margin-top: -4px !important;\n}\n\n.project-info-text:hover {\n  border-color: green;\n}\n\n.cancel-btn {\n  margin-left: 30px;\n  background-color: white !important;\n  color: green !important;\n  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);\n}\n\n.cancel-btn:hover {\n  background-color: lightgrey !important;\n}\n\n.create-btn {\n  margin-left: 10px;\n  background-color: green !important;\n  border-color: green !important;\n  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);\n}\n\n.create-btn:hover {\n  background-color: green !important;\n  opacity: 85%;\n}\n\n.createProjectForm {\n  margin-left: 15px;\n}\n\n.newProjectHr {\n  width: 600px;\n}\n\n.newProject {\n  margin-top: 10px;\n  font-size: 27px;\n  margin-left: 50px;\n  width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
