import React, { useState, useEffect } from 'react'
import UploadForm from '../../../components/UploadForm/UploadForm'
import AssessmentList from '../../../components/Forms/AssessmentList'
import { getContextQuestions, putContextQuestion, deleteContextQuestion, generateContextQuestion } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { useMyGlobalContext } from '../../../global/globalContext'
import toast from 'react-hot-toast'

const AssessmentFeatureForm = () => {
    const { globalState } = useMyGlobalContext()
    const [assessmentData, setAssessmentData] = useState([]);

    const init = async () => {
        try {
            const response = await getContextQuestions(globalState.projectId, globalState.assessmentId)
            const data = response.data
            const assessment = [
                {
                    category: 'Further Context Information',
                    elements: data.filter(singeData => singeData.Type == "business context").map(singleData => ({
                        title: singleData.Question,
                        value: singleData.Answer,
                        ...singleData
                    }))
                }
            ]
            setAssessmentData(assessment)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        init()
    }, [])

    const generateStuff = async () => {
        try {
            const response = await generateContextQuestion(globalState.projectId, globalState.assessmentId)
            toast.success(response.data.message)
        } catch (error) {
            toast.error("Generation start failed!")
        }
    }

    const handleElementDelete = async (id) => {
        try {
            await deleteContextQuestion(globalState.projectId, globalState.assessmentId, id)
            init()
            toast.success("Question deleted successfully!")
        } catch (error) {
            toast.error("Question deletion failed")
        }
    }

    const saveAllQuestions = async () => {
        try {
            let i = 0;
            for (i; i < assessmentData[0].elements.length; i++) {
                const element = assessmentData[0].elements[i]
                await putContextQuestion(globalState.projectId, globalState.assessmentId, element.id, {
                    "Name": element.Name,
                    "Question": element.Question,
                    "Answer": element.value,
                    "Type": element.Type
                })
            }
            toast.success("Questions saved successfully!")
        } catch (error) {
            toast.error("Failed to save")
        }
    }

    return (
        <div>
            <UploadForm />
            <button className='assessment-button mb-3' onClick={() => generateStuff()}>
                Generate
            </button>
            {assessmentData.length > 0 && <AssessmentList
                data={assessmentData}
                setData={setAssessmentData}
                deletable={true}
                generable={false}
                handleElementDelete={(id) => handleElementDelete(id)}
            />}
            <button className='assessment-button' style={{ float: "right" }} onClick={() => saveAllQuestions()}>
                Save
            </button>
        </div>
    )
}

export default AssessmentFeatureForm