// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
    margin-top: 20px;
}

.inline {
    display: flex;
    flex-wrap: nowrap;
}

.createApp-btn {
    background-color: #26890d !important;
    border-color: #26890d !important;
    font-weight: bold !important;
    transition: none !important;
    border-radius: unset !important;
}

.createApp-btn:hover {
    background-color: #43b02a !important;
    border-color: #43b02a !important;
    color: #000 !important;
}

.createApp-btn:hover i {
    color: #000 !important;
}

.iconTab {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/ApplicationBuild/ApplicationList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IAEI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,4BAA4B;IAC5B,2BAA2B;IAC3B,+BAA+B;AACnC;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".page-title {\n    margin-top: 20px;\n}\n\n.inline {\n    display: -webkit-flex;\n    display: flex;\n    flex-wrap: nowrap;\n}\n\n.createApp-btn {\n    background-color: #26890d !important;\n    border-color: #26890d !important;\n    font-weight: bold !important;\n    transition: none !important;\n    border-radius: unset !important;\n}\n\n.createApp-btn:hover {\n    background-color: #43b02a !important;\n    border-color: #43b02a !important;\n    color: #000 !important;\n}\n\n.createApp-btn:hover i {\n    color: #000 !important;\n}\n\n.iconTab {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
