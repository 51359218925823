// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-bar {
    width: 100%;
    font-family: Arial, sans-serif;
    background-color: #F5F5F5;
  }
  
  .title {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #02351C;
    font-size: 24px;
    display: flex;
    }
  
  .options {
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    width:95%;
    padding: 5px;
    border-radius: 5px;
  }
  
  .option {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
   }
  
  .option.selected {
    background-color: #86BC2529; /* Green background for selected */
    color: #86BC25;    
   }
   .option.show {
    background-color: #86BC2529; /* Green background for selected */
    color: #86BC25;    
   }
   .option.hide{
    background-color: #D9D9D9;
   }
  `, "",{"version":3,"sources":["webpack://./src/components/Bars/SelectedBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,aAAa;IACb;;EAEF;IACE,aAAa;IACb,6BAA6B;IAC7B,uBAAuB;IACvB,SAAS;IACT,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,eAAe;GAChB;;EAED;IACE,2BAA2B,EAAE,kCAAkC;IAC/D,cAAc;GACf;GACA;IACC,2BAA2B,EAAE,kCAAkC;IAC/D,cAAc;GACf;GACA;IACC,yBAAyB;GAC1B","sourcesContent":[".selected-bar {\n    width: 100%;\n    font-family: Arial, sans-serif;\n    background-color: #F5F5F5;\n  }\n  \n  .title {\n    margin-bottom: 10px;\n    font-size: 1.2em;\n    color: #02351C;\n    font-size: 24px;\n    display: flex;\n    }\n  \n  .options {\n    display: flex;\n    justify-content: space-evenly;\n    background-color: white;\n    width:95%;\n    padding: 5px;\n    border-radius: 5px;\n  }\n  \n  .option {\n    padding: 10px;\n    width: 100%;\n    border-radius: 5px;\n    display: flex;\n    justify-content: center;\n    cursor: pointer;\n   }\n  \n  .option.selected {\n    background-color: #86BC2529; /* Green background for selected */\n    color: #86BC25;    \n   }\n   .option.show {\n    background-color: #86BC2529; /* Green background for selected */\n    color: #86BC25;    \n   }\n   .option.hide{\n    background-color: #D9D9D9;\n   }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
