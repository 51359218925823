import { useState, useEffect } from 'react'
import UploadForm from '../../../components/UploadForm/UploadForm'
import AssessmentList from '../../../components/Forms/AssessmentList'
import { getInfraContext, putInfraContext, uploadInfraContext } from "../../../services/CloudMigrationAdvisor/InfrastructureAssessment"
import { useMyGlobalContext } from "../../../global/globalContext"
import toast from 'react-hot-toast'

const InfrAssessmentContext = () => {
    const [assessmentData, setAssessmentData] = useState([]);
    const {globalState} = useMyGlobalContext()

    const initData = async () => {
        try {
            const response = await getInfraContext(globalState.projectId, globalState.assessmentId)
            const data = response.data
            const assessment = [{
                category: "Infrastructure context",
                elements: [
                    {
                        title: "Infrastructure description",
                        value: data.Description
                    },
                    {
                        title: "Computing Infrastructure description",
                        value: data.ComputingInfraDescription
                    },
                    {
                        title: "Network Infrastructure description",
                        value: data.NetworkInfraDescription
                    },
                    {
                        title: "Storage Infrastructure description",
                        value: data.StorageInfraDescription
                    },
                    {
                        title: "Backup Infrastructure description",
                        value: data.BackupInfraDescription
                    },
                    {
                        title: "Disaster Recovery Infrastructure description",
                        value: data.DisasterRecoveryInfraDescription
                    },
                    {
                        title: "Cloud Infrastructure description",
                        value: data.CloudInfraDescription
                    },
                    {
                        title: "Other Infrastructure Info",
                        value: data.OtherInfraRelevantInfo
                    },
                ]
            }]
            setAssessmentData(assessment)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        initData()
    }, [])

    const handleSave = async () => {
        try {
            const body = {
                Description: assessmentData[0].elements[0].value,
                ComputingInfraDescription: assessmentData[0].elements[1].value,
                NetworkInfraDescription: assessmentData[0].elements[2].value,
                StorageInfraDescription: assessmentData[0].elements[3].value,
                BackupInfraDescription: assessmentData[0].elements[4].value,
                DisasterRecoveryInfraDescription: assessmentData[0].elements[5].value,
                CloudInfraDescription: assessmentData[0].elements[6].value,
                OtherInfraRelevantInfo: assessmentData[0].elements[7].value,
            }
            const response = await putInfraContext(globalState.projectId, globalState.assessmentId, body)
            toast.success("Infrastructure context saved successfully!")
        } catch (error) {
            toast.error("Infrastructure context saving failed")
        }
    }

    const handleUpload = async (formData) =>{
        try{
            await uploadInfraContext(globalState.projectId, globalState.assessmentId, formData)
            toast.success("Infrastructure context upload succeeded!")
        } catch (error) {
            toast.error("Infrastructure context upload failed")
        }
      }

    return (
        <>
            <UploadForm handleUpload={(formData)=>handleUpload(formData)} confirmUpload={false}/>
            <AssessmentList data={assessmentData} setData={setAssessmentData} generable={false} />
            <button className='assessment-button' style={{float:"right"}} onClick={() => handleSave()}>Save</button>
        </>
    )
}

export default InfrAssessmentContext