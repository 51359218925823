// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.chat-container {
}

 
  .chat-window {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .message-list {
    padding: 10px;
    max-height: 500px;
    min-height: 500px;
    overflow-y: scroll;
  }
  
  .message {
    background-color: #f5f5f5;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
  }
  
  .dark {
    background-color: #c5c5c5;
  }

  .user {
    font-weight: bold;
  }
  
  .input-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  input[type="text"] {
    flex: 1 1;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  
.chat-list {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top:10px;
  padding:4px;
}

.chat-item {
  background-color: #f5f5f5;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.chat-item-title {
  
}

.chat-item-date {
  font-weight: bold;
  font: size 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Utils/ModalConfirm.css"],"names":[],"mappings":";AACA;AACA;;;EAGE;IACE,WAAW;IACX,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,SAAO;IACP,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;;AAGF;EACE,WAAW;EACX,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;;AAEA;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":["\n.chat-container {\n}\n\n \n  .chat-window {\n    width: 100%;\n    margin: 0 auto;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    overflow: hidden;\n  }\n  \n  .message-list {\n    padding: 10px;\n    max-height: 500px;\n    min-height: 500px;\n    overflow-y: scroll;\n  }\n  \n  .message {\n    background-color: #f5f5f5;\n    padding: 10px;\n    margin: 5px;\n    border-radius: 5px;\n  }\n  \n  .dark {\n    background-color: #c5c5c5;\n  }\n\n  .user {\n    font-weight: bold;\n  }\n  \n  .input-area {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n  }\n  \n  input[type=\"text\"] {\n    flex: 1;\n    padding: 5px;\n    border-radius: 5px;\n    margin-right: 10px;\n  }\n  \n  \n.chat-list {\n  width: 100%;\n  margin: 0 auto;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  overflow: hidden;\n  margin-top:10px;\n  padding:4px;\n}\n\n.chat-item {\n  background-color: #f5f5f5;\n  padding: 10px;\n  margin: 5px;\n  border-radius: 5px;\n}\n\n.chat-item-title {\n  \n}\n\n.chat-item-date {\n  font-weight: bold;\n  font: size 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
