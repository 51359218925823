// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assessment-button{
    background-color: #43b02a;
    border-radius: 20px;
    padding:0.1rem 2rem;
    color:white;
    border-color: #D0D0CE;
    margin:0.25rem 0rem;
    border: 2px solid black !important;
}

.assessment-button:hover{
    opacity: 85%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".assessment-button{\n    background-color: #43b02a;\n    border-radius: 20px;\n    padding:0.1rem 2rem;\n    color:white;\n    border-color: #D0D0CE;\n    margin:0.25rem 0rem;\n    border: 2px solid black !important;\n}\n\n.assessment-button:hover{\n    opacity: 85%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
