// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contextTitle{
    font-weight: bold;
}

.contextHeader{
    margin-bottom: 20px;
}

.contextItem {
    list-style-type: disc !important;
    margin-bottom: 10px;
    border: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/Summary/Summary.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,YAAY;EACd","sourcesContent":[".contextTitle{\n    font-weight: bold;\n}\n\n.contextHeader{\n    margin-bottom: 20px;\n}\n\n.contextItem {\n    list-style-type: disc !important;\n    margin-bottom: 10px;\n    border: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
