// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.principalTitle{
    color: #43B02A;
    font-weight: bold;
    margin-left: 20px;
    font-size: 17px;
}

.makeFlex{
   display: flex;
   flex-wrap: wrap;
    margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/CloudSuitabilityAssessment.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;GACG,aAAa;GACb,eAAe;IACd,iBAAiB;AACrB","sourcesContent":[".principalTitle{\n    color: #43B02A;\n    font-weight: bold;\n    margin-left: 20px;\n    font-size: 17px;\n}\n\n.makeFlex{\n   display: flex;\n   flex-wrap: wrap;\n    margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
