// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benchmark-wrapper{
    display: flex;
    justify-content: center;
    gap:5rem;
}

.gray-bg{
    background-color: #D2D2D2;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    text-align: center;
    min-width: 200px;
    font-weight: 700;
}

.black-bg{
    font-weight: 600;
    color:white;
    background-color: black;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    min-width: 200px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/CloudMigrationAdvisor/InfrAssessmentHostObsolescence/InfrAssessmentHostObsolescence.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".benchmark-wrapper{\n    display: flex;\n    justify-content: center;\n    gap:5rem;\n}\n\n.gray-bg{\n    background-color: #D2D2D2;\n    padding: 0.25rem 0.5rem;\n    border-radius: 5px;\n    text-align: center;\n    min-width: 200px;\n    font-weight: 700;\n}\n\n.black-bg{\n    font-weight: 600;\n    color:white;\n    background-color: black;\n    padding: 0.25rem 0.5rem;\n    border-radius: 5px;\n    min-width: 200px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
