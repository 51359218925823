// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-wrapper {
    display: flex;
}

.breadcrumb-leaf {
    color: #86BC25;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

}

.breadcrumb-body {
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

}

.breadcrumb-body:hover{
    cursor: pointer;
}

.breadcrumb-wrapper span {
    color: #808080;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;;AAEpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".breadcrumb-wrapper {\n    display: flex;\n}\n\n.breadcrumb-leaf {\n    color: #86BC25;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    letter-spacing: 0px;\n    text-align: left;\n\n}\n\n.breadcrumb-body {\n    color: #808080;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    letter-spacing: 0px;\n    text-align: left;\n\n}\n\n.breadcrumb-body:hover{\n    cursor: pointer;\n}\n\n.breadcrumb-wrapper span {\n    color: #808080;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
