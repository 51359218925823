// InformationWithColumns.js
import React from 'react';
import './InformationWithColumns.css';

const InformationWithColumns = ({
    title,
    pillTitle,
    pillContent,
    leftColumnTitle,
    leftColumnContent,
    rightColumnTitle,
    rightColumnContent
}) => {
    return (
        <div className="information-container">
            <div className='header-pill-container'>
                <div className="header">
                    <h1>{title}</h1>
                </div>
                <div className="pill">
                    {<span className="pill-title">{pillTitle}</span>}
                    {<span className="pill-content">{pillContent}</span>}
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <h3>{leftColumnTitle}</h3>
                    <ul>
                        {leftColumnContent.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
                <div className="column">
                    <h3>{rightColumnTitle}</h3>
                    <ul>
                        {rightColumnContent.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default InformationWithColumns;
