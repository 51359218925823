// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-selection-wrapper{
    display: flex;
    gap:4px;

}

.button-selection{
    background-color: gray;
    padding:0.5rem 1.5rem;
    width:100%;
}

.button-selection:hover{
    cursor: pointer;
}

.button-selection.active{
    background-color: green;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectionWithButtons/SelectionWithButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,OAAO;;AAEX;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".buttons-selection-wrapper{\n    display: flex;\n    gap:4px;\n\n}\n\n.button-selection{\n    background-color: gray;\n    padding:0.5rem 1.5rem;\n    width:100%;\n}\n\n.button-selection:hover{\n    cursor: pointer;\n}\n\n.button-selection.active{\n    background-color: green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
