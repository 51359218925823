// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-information-container {
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.general-information-title {
  color: #02351C;
  /* Updated to the green shade */
  margin-bottom: 20px;
  font-weight: bold;
  /* Title bold */
  display: flex;
}

.information-item {
  display: flex;
  padding: 0 20px;
  /* Add padding to align with container's padding */
}

.item-title {
  color: #86BC25;
  font-size: 14px;
  font-weight: bold;
  /* Labels bold */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 170px;
  /* Right align the titles */
  /* Allows the title to fill the space */
}

.item-value {
  font-size: 14px;
  margin-left: 10px;
  /* Space between label and value */
  /* Adjusts the space for value, ensuring alignment */
  display: flex;
  align-items: center;
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/GeneralInformation/GeneralInformationContent.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,kDAAkD;AACpD;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,2BAA2B;EAC3B,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kCAAkC;EAClC,oDAAoD;EACpD,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".general-information-container {\n  border-radius: 8px;\n  padding: 20px;\n  width: 100%;\n}\n\n.general-information-title {\n  color: #02351C;\n  /* Updated to the green shade */\n  margin-bottom: 20px;\n  font-weight: bold;\n  /* Title bold */\n  display: flex;\n}\n\n.information-item {\n  display: flex;\n  padding: 0 20px;\n  /* Add padding to align with container's padding */\n}\n\n.item-title {\n  color: #86BC25;\n  font-size: 14px;\n  font-weight: bold;\n  /* Labels bold */\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  min-width: 170px;\n  /* Right align the titles */\n  /* Allows the title to fill the space */\n}\n\n.item-value {\n  font-size: 14px;\n  margin-left: 10px;\n  /* Space between label and value */\n  /* Adjusts the space for value, ensuring alignment */\n  display: flex;\n  align-items: center;\n  margin-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
