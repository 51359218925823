import './ApplicationSummaryCard.css'
import { useState } from 'react'
import SelectionWithButtons from '../SelectionWithButtons/SelectionWithButtons'
import DisplayInformation from '../DisplayInformation/DisplayInformation'

const ApplicationSummaryCard = ({
    title,
    description
}) => {
    const [criticallitySelection, setCriticallitySelection] = useState(0)
    const [traffitSelection, setTrafficSelection] = useState(0)
    const [softwareArchSelectio, setSoftwareArchSelection] = useState(0)
    const generalInfoData = { "Arch type": ["Monolithic"], "Available env": ["production", "test"], "N. Prod. servers": [3], "Customization degree": ["custom"], "functional area": ["financial accounting"] }
    const techStackData = {
        "Operating System":["Windows Server 2019"],
        "Level of Support OS":["Fully supported"],
        "Middleware":["Apache Tomcat 10","Nginx 0.7"],
        "Level of Support Middleware":["fully support","out of support"],
        "Runtime":["Java 1.7"],
        "Level of Support Runtime":["fully supported"]
    }
    return (
        <div className='single-card-wrapper'>
            <div className="application-card">
                {title}
            </div>
            <div className='single-card-content-wrapper row'>
                <div className='col'>
                    <div className='single-card-description'>
                        <span>Brief description</span>
                        <hr />
                        {description}
                    </div>
                    <div>
                        <SelectionWithButtons
                            title="Application Criticallity"
                            options={["high", "medium", "low"]}
                            selected={criticallitySelection}
                            setSelected={(newSelection) => setCriticallitySelection(newSelection)}
                            order='vertical'
                        />
                    </div>
                </div>
                <div className='col'>
                    <div>
                        <DisplayInformation
                            title="General Information"
                            data={generalInfoData}
                        />
                    </div>
                    <div>
                        <SelectionWithButtons
                            title="Criticality in managing data and traffic"
                            options={["ordinary", "important", "critical"]}
                            selected={traffitSelection}
                            setSelected={(newSelection) => setTrafficSelection(newSelection)}
                        />
                    </div>
                    <div>
                        <SelectionWithButtons
                            title="Code and software architecture quality"
                            options={["bad", "good", "weel developed"]}
                            selected={softwareArchSelectio}
                            setSelected={(newSelection) => setSoftwareArchSelection(newSelection)}
                        />
                    </div>
                </div>
                <div className='col'>
                    <div>
                    <DisplayInformation
                            title="Technology Stack"
                            data={techStackData}
                            bg="gray"
                            columns={2}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationSummaryCard