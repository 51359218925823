import UploadForm from "../../../components/UploadForm/UploadForm"
import EditableTable from "../../../components/Tables/EditableTable"
import { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
    uploadInfraServers,
    getVirtualClusters,
    deleteVirtualCluster,
    putVirtualCluster,
    postVirtualCluster,
    getClusters,
    deleteCluster,
    putCluster,
    postCluster,
    getServers,
    deleteServer,
    putServer,
    postServer,
    getHosts,
    deleteHost,
    putHost,
    postHost,
    updateInfraVirtualizationCluster
} from "../../../services/CloudMigrationAdvisor/InfrastructureAssessment"
import { useMyGlobalContext } from "../../../global/globalContext";
import toast from 'react-hot-toast'
import './InfrAssessmentDataIngestion.css'

const InfrAssessmentDataIngestion = () => {
    const { globalState } = useMyGlobalContext()
    const [selectedVCluster, setSelectedVCluster] = useState(null)
    const [selectedCluster, setSelectedCluster] = useState(null)
    const [vClustersOptions, setVClustersOptions] = useState([])
    const [clustersOptions, setClustersOptions] = useState([])
    const [isChecked, setIsChecked] = useState(false)

    const [VClusterData, setVClusterData] = useState([])
    const [indexIdMappingVirtualClusters, setIndexIdMappingVirtualClusters] = useState({})
    const [clusterData, setClusterData] = useState([])
    const [indexIdMappingClusters, setIndexIdMappingClusters] = useState({})
    const [serverData, setServerData] = useState([])
    const [indexIdMappingServers, setIndexIdMappingServers] = useState({})
    const [hostData, setHostData] = useState([])
    const [indexIdMappingHosts, setIndexIdMappingHosts] = useState({})
    const [VClusterHeaders] = useState([
        "Name",
        "Type",
        "Number of Physical Hosts",
        "Number of Virtual Machines",
        "Number of Physical Core",
        "Number of VirtualCPU",
        "VCPU to pCore Ratio",
        "Physical RAM",
        "Virtual RAM",
        "VRAM to pRAM Ration",
        "Number of Max Concurrent Faults"
    ])
    const [clusterHeaders] = useState([
        "Name",
        "Type",
        "Description",
        "Number of Servers",
    ])
    const [serverHeaders] = useState([
        "Name",
        "Operating_System",
        "CPU",
        "RAM",
        "Storage",
        "Type"
    ])
    const [hostHeaders] = useState([
        "Name",
        "Socket",
        "PhysicalCore",
        "PhysicalRAM",
        "Model",
        "Vendor",
        "CPUModel",
        "SPEC_CPU_2017_Speed_Integer",
        "SPEC_CPU_2017_Rate_Integer",
    ])

    const handleUpload = async (formData) => {
        try {
            await uploadInfraServers(globalState.projectId, globalState.assessmentId, formData)
        } catch (error) {
            console.log(error)
        }
    }

    const init = async () => {
        initVirtualClusters()
        initClusters()
        initServers()
        initHosts()
    }

    const initVirtualClusters = async () => {
        try {
            const response = await getVirtualClusters(globalState.projectId, globalState.assessmentId)
            let tempMapping = {}
            let options = []
            setVClusterData(response.data.map((singelVCluster, index) => {
                tempMapping[index] = singelVCluster.id
                options.push({ id: singelVCluster.id, label: singelVCluster.Name })
                return [
                    singelVCluster.Name,
                    singelVCluster.Type,
                    singelVCluster.Number_of_Physical_Hosts,
                    singelVCluster.Number_of_Virtual_Machines,
                    singelVCluster.Number_of_PhysicalCore,
                    singelVCluster.Number_of_VirtualCPU,
                    singelVCluster.vCPU_to_pCore_Ratio,
                    singelVCluster.PhysicalRAM,
                    singelVCluster.VirtualRAM,
                    singelVCluster.vRAM_to_pRAM_Ratio,
                    singelVCluster.Number_of_Max_Concurrent_Faults,
                ]
            }
            ))
            setIndexIdMappingVirtualClusters(tempMapping)
            setVClustersOptions(options)
        } catch (error) {
            console.log(error)
        }
    }

    const initClusters = async () => {
        try {
            const response = await getClusters(globalState.projectId, globalState.assessmentId)
            let tempMapping = {}
            let options = []
            setClusterData(response.data.map((singelCluster, index) => {
                tempMapping[index] = singelCluster.id
                options.push({ id: singelCluster.id, label: singelCluster.Name })
                return [
                    singelCluster.Name,
                    singelCluster.Type,
                    singelCluster.Description,
                    singelCluster.Number_of_Servers,
                ]
            }
            ))
            setIndexIdMappingClusters(tempMapping)
            setClustersOptions(options)
        } catch (error) {
            console.log(error)
        }
    }

    const initServers = async () => {
        try {
            if (!selectedCluster && !selectedVCluster && !isChecked) return
            const response = await getServers(globalState.projectId, globalState.assessmentId)
            let tempMapping = {}
            if (isChecked) {
                setServerData(response.data.filter(singelServer => !singelServer.Cluster&& !singelServer.VirtualizationCluster).map((singelServer, index) => {
                    tempMapping[index] = singelServer.id
                    return [
                        singelServer.Name,
                        singelServer.Operating_System,
                        singelServer.CPU,
                        singelServer.RAM,
                        singelServer.Storage,
                        singelServer.Type
                    ]
                }
                ))
            } else {
                setServerData(response.data.filter(singelServer => selectedCluster ? selectedCluster.id == singelServer.Cluster : selectedVCluster.id == singelServer.VirtualizationCluster).map((singelServer, index) => {
                    tempMapping[index] = singelServer.id
                    return [
                        singelServer.Name,
                        singelServer.Operating_System,
                        singelServer.CPU,
                        singelServer.RAM,
                        singelServer.Storage,
                        singelServer.Type
                    ]
                }
                ))
            }
            setIndexIdMappingServers(tempMapping)
        } catch (error) {
            console.log(error)
        }
    }

    const initHosts = async () => {
        try {
            if (!selectedVCluster) return
            const response = await getHosts(globalState.projectId, globalState.assessmentId, selectedVCluster.id)
            let tempMapping = {}
            setHostData(response.data.map((singleHost, index) => {
                tempMapping[index] = singleHost.id
                return [
                    singleHost.Name,
                    singleHost.Socket,
                    singleHost.PhysicalCore,
                    singleHost.PhysicalRAM,
                    singleHost.Model,
                    singleHost.Vendor,
                    singleHost.CPUModel,
                    singleHost.SPEC_CPU_2017_Speed_Integer,
                    singleHost.SPEC_CPU_2017_Rate_Integer,
                ]
            }
            ))
            setIndexIdMappingHosts(tempMapping)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        initServers()
        if (selectedVCluster) initHosts()
    }, [selectedCluster, selectedVCluster, isChecked])

    const handleSingleSaveVCluster = async (index, rowData) => {
        try {
            if (indexIdMappingVirtualClusters[index]) {
                const body = {
                    Name: rowData[0],
                    Type: rowData[1],
                    Number_of_Physical_Hosts: rowData[2],
                    Number_of_Virtual_Machines: rowData[3],
                    Number_of_PhysicalCore: rowData[4],
                    Number_of_VirtualCPU: rowData[5],
                    vCPU_to_pCore_Ratio: rowData[6],
                    PhysicalRAM: rowData[7],
                    VirtualRAM: rowData[8],
                    vRAM_to_pRAM_Ratio: rowData[9],
                    Number_of_Max_Concurrent_Faults: rowData[10]
                }
                await putVirtualCluster(globalState.projectId, globalState.assessmentId, indexIdMappingVirtualClusters[index], body)
            } else {
                const body = {
                    Name: rowData[0],
                    Type: rowData[1],
                    Number_of_Physical_Hosts: rowData[2],
                    Number_of_Virtual_Machines: rowData[3],
                    Number_of_PhysicalCore: rowData[4],
                    Number_of_VirtualCPU: rowData[5],
                    vCPU_to_pCore_Ratio: rowData[6],
                    PhysicalRAM: rowData[7],
                    VirtualRAM: rowData[8],
                    vRAM_to_pRAM_Ratio: rowData[9],
                    Number_of_Max_Concurrent_Faults: rowData[10]
                }
                await postVirtualCluster(globalState.projectId, globalState.assessmentId, body)
                // console.log(body)
            }
            initVirtualClusters()
            toast.success("Virtual cluster saved successfully!")
        } catch (error) {
            toast.error("Virtual cluster saving failed")
        }
    }

    const handleSingleSaveCluster = async (index, rowData) => {
        try {
            if (indexIdMappingClusters[index]) {
                const body = {
                    Name: rowData[0],
                    Type: rowData[1],
                    Description: rowData[2],
                    Number_of_Servers: rowData[3],
                }
                await putCluster(globalState.projectId, globalState.assessmentId, indexIdMappingClusters[index], body)
            } else {
                const body = {
                    Name: rowData[0],
                    Type: rowData[1],
                    Description: rowData[2],
                    Number_of_Servers: rowData[3],
                }
                await postCluster(globalState.projectId, globalState.assessmentId, body)
                // console.log(body)
            }
            initClusters()
            toast.success("Cluster saved successfully!")
        } catch (error) {
            toast.error("Cluster saving failed")
        }
    }

    const handleSingleSaveServer = async (index, rowData) => {
        try {
            if (indexIdMappingServers[index]) {
                const body = {
                    Name: rowData[0],
                    Operating_System: rowData[1],
                    CPU: rowData[2],
                    RAM: rowData[3],
                    Storage: rowData[4],
                    Type: rowData[5],
                }
                await putServer(globalState.projectId, globalState.assessmentId, indexIdMappingServers[index], body)
            } else {
                if (!selectedCluster && !selectedVCluster && !isChecked) return
                const body = {
                    Name: rowData[0],
                    Operating_System: rowData[1],
                    CPU: rowData[2],
                    RAM: rowData[3],
                    Storage: rowData[4],
                    Type: rowData[5],
                    VirtualizationCluster: selectedVCluster ? selectedVCluster.id : undefined,
                    Cluster: selectedCluster ? selectedCluster.id : undefined
                }
                await postServer(globalState.projectId, globalState.assessmentId, body)
                // console.log(body)
            }
            initServers()
            toast.success("Server saved successfully!")
        } catch (error) {
            toast.error("Server saving failed")
        }
    }

    const handleSingleSaveHost = async (index, rowData) => {
        try {
            if (!selectedVCluster) return
            if (indexIdMappingHosts[index]) {
                const body = {
                    Name: rowData[0],
                    Socket: rowData[1],
                    PhysicalCore: rowData[2],
                    PhysicalRAM: rowData[3],
                    Model: rowData[4],
                    Vendor: rowData[5],
                    CPUModel: rowData[6],
                    SPEC_CPU_2017_Speed_Integer: rowData[7],
                    SPEC_CPU_2017_Rate_Integer: rowData[8],
                }
                await putHost(globalState.projectId, globalState.assessmentId, selectedVCluster.id, indexIdMappingHosts[index], body)
            } else {
                const body = {
                    Name: rowData[0],
                    Socket: rowData[1],
                    PhysicalCore: rowData[2],
                    PhysicalRAM: rowData[3],
                    Model: rowData[4],
                    Vendor: rowData[5],
                    CPUModel: rowData[6],
                    SPEC_CPU_2017_Speed_Integer: rowData[7],
                    SPEC_CPU_2017_Rate_Integer: rowData[8],
                }
                await postHost(globalState.projectId, globalState.assessmentId, selectedVCluster.id, body)
                // console.log(body)
            }
            initHosts()
            toast.success("Host saved successfully!")
        } catch (error) {
            toast.error("Host saving failed")
        }
    }

    const deleteSingleVirtualCluster = async (index) => {
        try {
            if (indexIdMappingVirtualClusters[index]) {
                await deleteVirtualCluster(globalState.projectId, globalState.assessmentId, indexIdMappingVirtualClusters[index])
                initVirtualClusters()
            } else {
                setVClusterData([...VClusterData.slice(0, index), ...VClusterData.slice(index + 1)])
            }
            toast.success("Virtual Cluster deleted successfully!")
        } catch (error) {
            toast.error("Virtual Cluster deletion failed")
        }
    }

    const deleteSingleCluster = async (index) => {
        try {
            if (indexIdMappingClusters[index]) {
                await deleteCluster(globalState.projectId, globalState.assessmentId, indexIdMappingClusters[index])
                initClusters()
            } else {
                setClusterData([...clusterData.slice(0, index), ...clusterData.slice(index + 1)])
            }
            toast.success("Cluster deleted successfully!")
        } catch (error) {
            toast.error("Cluster deleting failed")
        }
    }

    const deleteSingleServer = async (index) => {
        try {
            if (indexIdMappingServers[index]) {
                await deleteServer(globalState.projectId, globalState.assessmentId, indexIdMappingServers[index])
                initServers()
            } else {
                setServerData([...serverData.slice(0, index), ...serverData.slice(index + 1)])
            }
            toast.success("Server deleted successfully!")
        } catch (error) {
            toast.error("Server deteling failed")
        }
    }

    const deleteSingleHost = async (index) => {
        try {
            if (indexIdMappingHosts[index]) {
                await deleteHost(globalState.projectId, globalState.assessmentId, selectedVCluster.id, indexIdMappingHosts[index])
                initHosts()
            } else {
                setHostData([...hostData.slice(0, index), ...hostData.slice(index + 1)])
            }
            toast.success("Host deleted successfully!")
        } catch (error) {
            toast.error("Host deleting failed")
        }
    }

    const handleUpdate = async() =>{
        try{
            await updateInfraVirtualizationCluster(globalState.projectId, globalState.assessmentId)
            toast.success("Virtual clusters updated successfully!")
            initVirtualClusters()   
        } catch (error) {
            toast.error("Virtual clusters update failed")
        }
    }

    return (
        <div className="infr-ingestion-wrapper">
            <UploadForm
                downloadable={true}
                downloadUrl={`${process.env.REACT_APP_SERVER_URL}/templates/download/Infrastructure_Template/`}
                confirmUpload={false}
                showFiles={false}
                handleUpload={handleUpload}
            />
            <button className='assessment-button' onClick={()=>handleUpdate()}>
                Update
            </button>
            <Tabs
                defaultActiveKey="virtualClusters"
                id="uncontrolled-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="virtualClusters" title="Virtual Clusters">
                    <EditableTable
                        headers={VClusterHeaders}
                        data={VClusterData}
                        setData={setVClusterData}
                        addRowFlag={true}
                        saveFlag={true}
                        deleteFlag={true}
                        deleteFunction={(index) => deleteSingleVirtualCluster(index)}
                        saveFunction={(index, rowData) => handleSingleSaveVCluster(index, rowData)}
                    />
                </Tab>
                <Tab eventKey="clusters" title="Clusters">
                    <EditableTable
                        headers={clusterHeaders}
                        data={clusterData}
                        setData={setClusterData}
                        addRowFlag={true}
                        saveFlag={true}
                        deleteFlag={true}
                        deleteFunction={(index) => deleteSingleCluster(index)}
                        saveFunction={(index, rowData) => handleSingleSaveCluster(index, rowData)}
                    />
                </Tab>
            </Tabs>
            <hr />
            <select className="mx-3" value={selectedVCluster ? selectedVCluster.id : ''} onChange={(e) => { setIsChecked(false); setSelectedCluster(null); vClustersOptions.map(option => option.id == e.target.value && setSelectedVCluster(option)) }}>
                <option value={''}>-- Select Virtual Cluster --</option>
                {
                    vClustersOptions.map((vClusterOption, index) => (
                        <option key={index} value={vClusterOption.id}> {vClusterOption.label} </option>
                    ))
                }
            </select>
            <select className="mx-3" value={selectedCluster ? selectedCluster.id : ''} onChange={(e) => { setIsChecked(false); setSelectedVCluster(null); clustersOptions.map(option => option.id == e.target.value && setSelectedCluster(option)) }}>
                <option value={''}>-- Select Cluster --</option>
                {
                    clustersOptions.map((clusterOption, index) => (
                        <option key={index} value={clusterOption.id}> {clusterOption.label} </option>
                    ))
                }
            </select>
            <label className="mx-3">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => { setIsChecked(!isChecked); setSelectedVCluster(null); setSelectedCluster(null) }}
                />
                Servers with no clusters
            </label>
            {selectedCluster || selectedVCluster || isChecked ?
                <Tabs
                    defaultActiveKey="servers"
                    id="uncontrolled-tab-example-extra"
                    className="my-3"
                    justify
                >
                    <Tab eventKey="servers" title="Servers">
                        <EditableTable
                            headers={serverHeaders}
                            data={serverData}
                            setData={setServerData}
                            addRowFlag={true}
                            saveFlag={true}
                            deleteFlag={true}
                            deleteFunction={(index) => deleteSingleServer(index)}
                            saveFunction={(index, rowData) => handleSingleSaveServer(index, rowData)}
                        />
                    </Tab>
                    {selectedVCluster &&
                        <Tab eventKey="hosts" title="Hosts">
                            <EditableTable
                                headers={hostHeaders}
                                data={hostData}
                                setData={setHostData}
                                addRowFlag={true}
                                saveFlag={true}
                                deleteFlag={true}
                                deleteFunction={(index) => deleteSingleHost(index)}
                                saveFunction={(index, rowData) => handleSingleSaveHost(index, rowData)}
                            />
                        </Tab>
                    }
                </Tabs>
                : ""}

        </div>
    )
}

export default InfrAssessmentDataIngestion