// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-wrapper{
    display: flex;
}

.content-wrapper{
    width: 90%;
}

.tabs-icons-wrapper{
    align-self: center;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.single-icon{
    border-style: solid;
    border-width: 2px;
    padding:4px;
    border-radius: 45px;
    background-color: #D2D2D2;
    border-color: #D2D2D2
}
.single-icon.active{
    background-color: yellow;
    border-color: green;
}
.single-icon:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SideTabs/SideTabs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB;AACJ;AACA;IACI,wBAAwB;IACxB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB","sourcesContent":[".tabs-wrapper{\n    display: flex;\n}\n\n.content-wrapper{\n    width: 90%;\n}\n\n.tabs-icons-wrapper{\n    align-self: center;\n    display: flex;\n    flex-direction: column;\n    gap:0.5rem;\n}\n\n.single-icon{\n    border-style: solid;\n    border-width: 2px;\n    padding:4px;\n    border-radius: 45px;\n    background-color: #D2D2D2;\n    border-color: #D2D2D2\n}\n.single-icon.active{\n    background-color: yellow;\n    border-color: green;\n}\n.single-icon:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
