import { FaRobot } from 'react-icons/fa';
import './RobotPopover.css'; // Create a CSS file for styling

const Popover = ({ poppoverPosition, children }) => {


    return (
        <div style={{maxWidth:"900px"}} className={`popover ${poppoverPosition}`} >
            {children}
        </div >
    );
};

const RobotPopover = ({ text, position }) => {

    return (
        <div className={`robot-container ${position}`}>
            <Popover poppoverPosition={position}>
                <p>{text}</p>
            </Popover>
            <FaRobot className={`robot-icon ${position}`} />

        </div>
    );
};

export default RobotPopover;
