// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
.robot-icon.right {
  font-size: 2em;
   color: black;
    margin-left:5;
 }
 
 .robot-icon.left {
  font-size: 2em;
   color: black;
    margin-left:17rem;
 }



.popover.right{
  padding: 0.5rem;
  margin-top:1rem;
  margin-left: 2rem;
}
.popover.left{
  padding: 0.5rem;
  margin-top:1rem;
  margin-left: -0rem;
   
}`, "",{"version":3,"sources":["webpack://./src/components/Popovers/RobotPopover.css"],"names":[],"mappings":";AACA;EACE,cAAc;GACb,YAAY;IACX,aAAa;CAChB;;CAEA;EACC,cAAc;GACb,YAAY;IACX,iBAAiB;CACpB;;;;AAID;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;;AAEpB","sourcesContent":[" \n.robot-icon.right {\n  font-size: 2em;\n   color: black;\n    margin-left:5;\n }\n \n .robot-icon.left {\n  font-size: 2em;\n   color: black;\n    margin-left:17rem;\n }\n\n\n\n.popover.right{\n  padding: 0.5rem;\n  margin-top:1rem;\n  margin-left: 2rem;\n}\n.popover.left{\n  padding: 0.5rem;\n  margin-top:1rem;\n  margin-left: -0rem;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
