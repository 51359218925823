// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* textOffCanvas.css */

.mainCanvas {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35rem;
  width:1rem;
  border-radius: 2rem;
  margin-top: 7rem;
 }

 .sumbitBtn{

  background-color: #26890d;
  color:white;
 }
 
 .sumbitBtn:hover{
    background-color: #1e6e09;
    color: black;
 }
 .buttonContainer{
  display: flex;
  justify-content: space-around;
  background-color: red !important;
 }`, "",{"version":3,"sources":["webpack://./src/components/TextOffCanvas/textOffCanvas.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,gBAAgB;CACjB;;CAEA;;EAEC,yBAAyB;EACzB,WAAW;CACZ;;CAEA;IACG,yBAAyB;IACzB,YAAY;CACf;CACA;EACC,aAAa;EACb,6BAA6B;EAC7B,gCAAgC;CACjC","sourcesContent":["/* textOffCanvas.css */\n\n.mainCanvas {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 35rem;\n  width:1rem;\n  border-radius: 2rem;\n  margin-top: 7rem;\n }\n\n .sumbitBtn{\n\n  background-color: #26890d;\n  color:white;\n }\n \n .sumbitBtn:hover{\n    background-color: #1e6e09;\n    color: black;\n }\n .buttonContainer{\n  display: flex;\n  justify-content: space-around;\n  background-color: red !important;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
