import { useState, useEffect } from 'react'
import EditableTable from '../../../components/Tables/EditableTable'
import RobotPopover from '../../../components/Popovers/RobotPopover'
import './InfrAssessmentHostObsolescence.css'
import { generateHostObsolescence, getHostObsolescence } from '../../../services/CloudMigrationAdvisor/InfrastructureAssessment'
import { getSummary } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { useMyGlobalContext } from '../../../global/globalContext'
import toast from 'react-hot-toast'

const InfrAssessmentHostObsolescence = () => {
    const [tableData, setTableData] = useState([])
    const [headers] = useState(["Model","CPU Model","Speed SPEC Score","Rate SPEC Score","# of Hosts"])
    const [robotMessage, setRobotMessage] = useState()
    const {globalState} = useMyGlobalContext()
    const [specBenchmark,setSpecBenchmark] = useState({
        speedSpecBenchmark:"",
        rateSpecBenchmark:""
    })

    const handleGenerate = async() =>{
        try{
            await generateHostObsolescence(globalState.projectId,globalState.assessmentId)
            toast.success("Infrastructure Obsolescence generation started!")
        } catch (error) {
            toast.error("Infrastructure Obsolescence generated start failed")
        }
    }

    const init = ()=>{
        initTable()
        initMessage()
    }

    const initTable = async()=>{
        try{
            const response = await getHostObsolescence(globalState.projectId,globalState.assessmentId)
            const data = response.data
            setSpecBenchmark({
                speedSpecBenchmark:data?.maxSpeedSpecScore,
                rateSpecBenchmark:data?.maxRateSpecScore
            })
            setTableData(data.data.map(model=>([
                model.model,
                model.cpuModel,
                model.speedSpecScore,
                model.rateSpecScore,
                model.numServers,
            ])))
        }catch(error){
            console.log(error)
        }
    }

    const initMessage = async()=>{
        try{
            const response = await getSummary(globalState.projectId,globalState.assessmentId)
            setRobotMessage(response.data)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        init()
    },[])

    return (
        <div>
            <button className='assessment-button' onClick={()=>handleGenerate()}>
                Generate
            </button>
            <div className='benchmark-wrapper mb-3'>
                <div className='gray-bg'>
                    Speed SPEC Benchmark
                </div>
                <div className='black-bg'>
                    {specBenchmark.speedSpecBenchmark}
                </div>
            </div>
            <div className='benchmark-wrapper  mb-3'>
                <div className='gray-bg'>
                    Rate SPEC Benchmark
                </div>
                <div className='black-bg'>
                {specBenchmark.rateSpecBenchmark}
                </div>
            </div>
            <EditableTable disabled={true} data={tableData} setData={setTableData} headers={headers} />
            <RobotPopover
                text={robotMessage?.HostObsolence_Analysis_Consideration?robotMessage.HostObsolence_Analysis_Consideration:""}
                position="right"
            />
        </div>
    )
}

export default InfrAssessmentHostObsolescence