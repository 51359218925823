import { useState, useEffect } from 'react'
import EditableTable from '../../../components/Tables/EditableTable'
import RobotPopover from '../../../components/Popovers/RobotPopover'
import { getSummary } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import {
    getInfraVirtualizationCluster,
    generateInfraFault
} from '../../../services/CloudMigrationAdvisor/InfrastructureAssessment'
import { useMyGlobalContext } from '../../../global/globalContext'
import toast from 'react-hot-toast'

const InfrAssessmentHostObsolescence = () => {
    useEffect(()=>{
        init()
    },[])

    const[data, setData]=useState()
    const {globalState} = useMyGlobalContext()
    const init = async()=>{
        try{
            const response = await getSummary(globalState.projectId, globalState.assessmentId)
            const response2 = await getInfraVirtualizationCluster(globalState.projectId, globalState.assessmentId)
            const data = response2.data
            setData(response.data)
            setTableData(data.map(singleCluster=>([
                singleCluster.Name,
                singleCluster.Number_of_Virtual_Machines,
                singleCluster.vCPU_to_pCore_Ratio,
                singleCluster.vRAM_to_pRAM_Ratio,
                singleCluster.Number_of_Max_Concurrent_Faults,
            ])))
        }catch(error){
            console.log(error)
        }
    }
    const [tableData, setTableData] = useState([])
    const [headers, setHeaders] = useState([
        "Name",
        "# of VirtualMachines",
        "vCPU to pCore Ratio",
        "vRAM to pRam Ratio",
        "# of Max Concurrent Faults"
    ])

    const handleGenerate = async() =>{
        try{
            await generateInfraFault(globalState.projectId, globalState.assessmentId)
            toast.success("Infrastructure Fault Tolerance generation started!")
        } catch (error) {
            toast.error("Infrastructure Fault Tolerance generated start failed")
        }
    }

    return (
        <div>
            <div style={{justifyContent:"center",display:"flex",height:"400px",width:"100%"}}>
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/virtualizationclustersaturation/`}
                    alt="saturation graph"
                    style={{width:"600px"}}
                />
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/virtualizationclusterfaulttolerance/`}
                    alt="fault tolerance graph"
                    style={{width:"600px"}}
                />
            </div>
            <div style={{justifyContent:"center",display:"flex",height:"400px",width:"100%"}}>
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/serveros/`}
                    alt="server os graph"
                    style={{width:"600px"}}
                />
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}/project/${globalState.projectId}/assessment/${globalState.assessmentId}/diagram/serverospie/`}
                    alt="server os pie"
                    style={{width:"600px"}}
                />
            </div>
            <EditableTable data={tableData} setData={setTableData} headers={headers} disabled={true} />
            <button className='assessment-button' onClick={()=>handleGenerate()}>
                Generate
            </button>
            <RobotPopover
                text={data?data.FaultTolerance_Analysis_Consideration:""}
                position="right"
            />
        </div>
    )
}

export default InfrAssessmentHostObsolescence