// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AssessmentList.css */

.assessment-list-container {
  /* Add any styles for the outer container as needed */
  margin-top :3rem;
}

.category-container {
  width: 100%;
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically within the container */
  margin-bottom: 2.4rem; /* Add margin for better spacing */
  
 }

.category-label {
  /* Add any specific styles for the category label */
  /* Adjust the margin as needed for spacing between label and elements */
  font-weight: bold;
  font-size: 18px;
  background-color: #43b02a;
  padding:3rem 1.1rem;
  rotate: 180deg;
  writing-mode: vertical-lr;
  color: white;
}

.elements-container {
  /* Add any styles for the container of elements */
  display: flex;
  flex-direction: column;
  width:100%;
  /* Align items in a column */
}

.textfield {
  width: calc(100% - 40px);
  height: 140px;
  margin-right: 30px;
  resize: none;
}

.title {
  display: flex;
  font-size: 18px;
}

.textfield-wrapper{
  display: flex;
  margin-bottom: 1rem;
}

.invisible-button{
  background: none;
  border: none
}

.buttons-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/AssessmentList.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,qDAAqD;EACrD,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB,EAAE,yBAAyB;EAC9C,mBAAmB,EAAE,iDAAiD;EACtE,qBAAqB,EAAE,kCAAkC;;CAE1D;;AAED;EACE,mDAAmD;EACnD,uEAAuE;EACvE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,iDAAiD;EACjD,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB","sourcesContent":["/* AssessmentList.css */\n\n.assessment-list-container {\n  /* Add any styles for the outer container as needed */\n  margin-top :3rem;\n}\n\n.category-container {\n  width: 100%;\n  display: flex;\n  flex-direction: row; /* Align items in a row */\n  align-items: center; /* Center items vertically within the container */\n  margin-bottom: 2.4rem; /* Add margin for better spacing */\n  \n }\n\n.category-label {\n  /* Add any specific styles for the category label */\n  /* Adjust the margin as needed for spacing between label and elements */\n  font-weight: bold;\n  font-size: 18px;\n  background-color: #43b02a;\n  padding:3rem 1.1rem;\n  rotate: 180deg;\n  writing-mode: vertical-lr;\n  color: white;\n}\n\n.elements-container {\n  /* Add any styles for the container of elements */\n  display: flex;\n  flex-direction: column;\n  width:100%;\n  /* Align items in a column */\n}\n\n.textfield {\n  width: calc(100% - 40px);\n  height: 140px;\n  margin-right: 30px;\n  resize: none;\n}\n\n.title {\n  display: flex;\n  font-size: 18px;\n}\n\n.textfield-wrapper{\n  display: flex;\n  margin-bottom: 1rem;\n}\n\n.invisible-button{\n  background: none;\n  border: none\n}\n\n.buttons-wrapper{\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
