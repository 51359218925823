// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color:#02351C;
     width: 100%;
  }
   
  

  .columns {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .column {
    background-color: #f0f0f0; /* Adjust color based on your design */
    padding: 20px;
    border-radius: 10px; /* This gives rounded corners */
     
    
  }
 
  
  .column h3 {
    margin-bottom: 10px;
    text-align: center;
}
  
  .column ul {
    list-style: none;
    padding: 0;
    
    
  }
  
  .column li {
    display: flex;
    font-weight: bold;
    justify-content: center;
    background-color: #D9D9D9;
     padding: 10px;
    }

 `, "",{"version":3,"sources":["webpack://./src/components/InformationWithColumns/InformationWithColumns.css"],"names":[],"mappings":";;EAEE;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;KACZ,WAAW;EACd;;;;EAIA;IACE,aAAa;IACb,6BAA6B;IAC7B,WAAW;EACb;;EAEA;IACE,yBAAyB,EAAE,sCAAsC;IACjE,aAAa;IACb,mBAAmB,EAAE,+BAA+B;;;EAGtD;;;EAGA;IACE,mBAAmB;IACnB,kBAAkB;AACtB;;EAEE;IACE,gBAAgB;IAChB,UAAU;;;EAGZ;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,yBAAyB;KACxB,aAAa;IACd","sourcesContent":[" \n  \n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    color:#02351C;\n     width: 100%;\n  }\n   \n  \n\n  .columns {\n    display: flex;\n    justify-content: space-evenly;\n    width: 100%;\n  }\n  \n  .column {\n    background-color: #f0f0f0; /* Adjust color based on your design */\n    padding: 20px;\n    border-radius: 10px; /* This gives rounded corners */\n     \n    \n  }\n \n  \n  .column h3 {\n    margin-bottom: 10px;\n    text-align: center;\n}\n  \n  .column ul {\n    list-style: none;\n    padding: 0;\n    \n    \n  }\n  \n  .column li {\n    display: flex;\n    font-weight: bold;\n    justify-content: center;\n    background-color: #D9D9D9;\n     padding: 10px;\n    }\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
