// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-buttons-wrapper{
    display: flex;
    justify-content: space-evenly;
}

.previous-section{
    border-radius: 12px;
    color:white;
    background-color: #046A38;
    align-self: center;
    padding: 8px;
}

.next-section{
    border-radius: 12px;
    color:white;
    background-color: #43b02a;
    align-self: center;
    padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/WizardNavigationButtons/WizardNavigationButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".navigation-buttons-wrapper{\n    display: flex;\n    justify-content: space-evenly;\n}\n\n.previous-section{\n    border-radius: 12px;\n    color:white;\n    background-color: #046A38;\n    align-self: center;\n    padding: 8px;\n}\n\n.next-section{\n    border-radius: 12px;\n    color:white;\n    background-color: #43b02a;\n    align-self: center;\n    padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
