// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.new-project-link{
    color:white;
    text-decoration: none;
 }
 .new-project-link:hover{
    color:black;
 }

.create-project-button{
    background-color: #26890d !important;
    border-color: #26890d !important;
    border-radius: 10px !important;
 }
 .create-project-button:hover{
    opacity: 85%;
 }

 .editStyle{
   color: black;
 }

.link-style:active{
    color: blue;
    text-decoration: underline;
}

.configButtons{
   cursor: pointer;
}

.editButton:hover{
   background-color: #26890d ;
}

.deleteButton:hover{
   background-color: red;
}

 .tableRows:hover .tName{
   cursor: pointer;
   text-decoration: underline;
   color: #26890d;
 }

 .deleteConfirm{
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .confirmationContent {
   text-align: center;
 }

 .projectName{
   color: #26890d;
 }

 .cancel-btn{
  margin-left: 30px;
  background-color: white !important;
  color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .cancel-btn:hover{
      background-color: lightgrey !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Projects/ProjectModal.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,qBAAqB;CACxB;CACA;IACG,WAAW;CACd;;AAED;IACI,oCAAoC;IACpC,gCAAgC;IAChC,8BAA8B;CACjC;CACA;IACG,YAAY;CACf;;CAEA;GACE,YAAY;CACd;;AAED;IACI,WAAW;IACX,0BAA0B;AAC9B;;AAEA;GACG,eAAe;AAClB;;AAEA;GACG,0BAA0B;AAC7B;;AAEA;GACG,qBAAqB;AACxB;;CAEC;GACE,eAAe;GACf,0BAA0B;GAC1B,cAAc;CAChB;;CAEA;GACE,aAAa;GACb,mBAAmB;GACnB,uBAAuB;CACzB;;CAEA;GACE,kBAAkB;CACpB;;CAEA;GACE,cAAc;CAChB;;CAEA;EACC,iBAAiB;EACjB,kCAAkC;EAClC,uBAAuB;EACvB,0CAA0C;EAC1C;;EAEA;MACI,sCAAsC;EAC1C","sourcesContent":["\n.new-project-link{\n    color:white;\n    text-decoration: none;\n }\n .new-project-link:hover{\n    color:black;\n }\n\n.create-project-button{\n    background-color: #26890d !important;\n    border-color: #26890d !important;\n    border-radius: 10px !important;\n }\n .create-project-button:hover{\n    opacity: 85%;\n }\n\n .editStyle{\n   color: black;\n }\n\n.link-style:active{\n    color: blue;\n    text-decoration: underline;\n}\n\n.configButtons{\n   cursor: pointer;\n}\n\n.editButton:hover{\n   background-color: #26890d ;\n}\n\n.deleteButton:hover{\n   background-color: red;\n}\n\n .tableRows:hover .tName{\n   cursor: pointer;\n   text-decoration: underline;\n   color: #26890d;\n }\n\n .deleteConfirm{\n   display: flex;\n   align-items: center;\n   justify-content: center;\n }\n\n .confirmationContent {\n   text-align: center;\n }\n\n .projectName{\n   color: #26890d;\n }\n\n .cancel-btn{\n  margin-left: 30px;\n  background-color: white !important;\n  color: green !important;\n  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);\n  }\n  \n  .cancel-btn:hover{\n      background-color: lightgrey !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
