import SideTabs from "../../../components/SideTabs/SideTabs"
import { useState } from 'react'
import UploadForm from '../../../components/UploadForm/UploadForm'
import Summary from '../../../components/Summary/Summary'
import AssessmentList from '../../../components/Forms/AssessmentList'
import { RiQuestionAnswerLine } from "react-icons/ri";
import { MdEditDocument } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";

const ApplicationContext = () => {
    const [assessmentData, setAssessmentData] = useState([
        {
            category: 'Application Landscape information',
            elements: [
                { title: 'Provide a general description of the application landscape', value: '' },
            ],
        },
        // ... other categories
    ]);

    const [furtherAssessmentData, setFurtherAssessmentData] = useState([
        {
            category: 'Further App Information',
            elements: [
                { title: 'How do you ensure the scalability and performance of these applications, especially during peak business times or as the company grows', value: '' },
                { title: 'How do these applications enhance user experience, both for employees and customers? Are there any user interface or experience enhancements planned', value: '' },
                { title: 'Who are the main vendors or service providers for these applications? How do you manage these vendor relationships', value: '' },
            ],
        },
        // ... other categories
    ]);

    const summaryData = {
        summaryTitle: 'BellaCalzature - Application Landscape Context Resume',
        data: [
            {
                title: 'Diverse Application Suite',
                content: 'Bella Calzature operates with over 30 specialized applications, including a custom ERP system for supply chain management, an advanced CRM platform for customer engagement, and a unique e-commerce platform for online sales.'
            },
            {
                title: 'Seamless Integration',
                content: 'Ensures real-time data exchange and operational efficiency through seamless integration across all applications using APIs and middleware.'
            },
            {
                title: 'Core Business Flows',
                content: 'Main business flows managed by these applications include inventory management, customer relationship management, sales processing, and after-sales service.'
            },
            {
                title: 'Mobile and Remote Access',
                content: 'Provides mobile and remote access to key applications, focusing on security and user experience, enabling flexibility for employees and customers.'
            },
            {
                title: 'Continuous Development and Upgrades',
                content: 'Actively invests in ongoing application development, with future plans to integrate AI and machine learning for improved analytics and customer service automation.'
            },
        ]
    }

    const AssessmentForm =
        <>
            <UploadForm />
            <AssessmentList data={assessmentData} setData={setAssessmentData} generable={true} />
        </>
    const FurtherAssessmentForm =
        <>
            <UploadForm />
            <AssessmentList data={furtherAssessmentData} setData={setFurtherAssessmentData} generable={true} deletable={true} />
        </>
    const AssessmentSummary =
        <>
            <Summary {...summaryData} />
        </>
    return (
        <SideTabs
            tabIcons={[RiQuestionAnswerLine, MdEditDocument, TiDocumentText]}
            tabsContent={[AssessmentForm, FurtherAssessmentForm, AssessmentSummary]}
        />
    )
}

export default ApplicationContext