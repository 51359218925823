// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-resume-wrapper{
    margin:auto;
    width:80%;
    display: flex;
    justify-content: center;
}
.download-resume-button{
    background-color: #86BC25;
    color:white;
    border-radius: 25px;
    border:none;
    padding:0.15rem 1.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/CMA/CMASummary/CMASummary.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,SAAS;IACT,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".download-resume-wrapper{\n    margin:auto;\n    width:80%;\n    display: flex;\n    justify-content: center;\n}\n.download-resume-button{\n    background-color: #86BC25;\n    color:white;\n    border-radius: 25px;\n    border:none;\n    padding:0.15rem 1.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
