import { useState, useEffect } from 'react'
import "./SideTabs.css"

const SideTabs = ({
    tabIcons,
    tabsContent
}) => {
    const [selectedIndex, setSelectedIndex] = useState()
    useEffect(() => {
        if (!tabsContent) return
        if (tabsContent.length > 0 && !selectedIndex) setSelectedIndex(0)
    }, [tabsContent])
    return (
        <div className='tabs-wrapper'>
            {tabIcons &&
                <div className='tabs-icons-wrapper'>
                    {tabIcons.map((Icon, index) => (
                        <div className={`single-icon${index==selectedIndex?" active":""}`} key={index} onClick={() => setSelectedIndex(index)}><Icon size="2rem"/> </div>
                    ))}
                </div>}
            {
                selectedIndex != null &&
                <div className='content-wrapper'>
                    {tabsContent[selectedIndex]}
                </div>
            }
        </div>
    )
}

export default SideTabs