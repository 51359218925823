// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-cards-wrapper{
    display: flex;
    gap:0.2rem;
}

.application-card{
    width: 100%;
    background-color: aqua;
    padding:0.1rem 4rem;
}

.single-card-description{
    min-height:200px;
}`, "",{"version":3,"sources":["webpack://./src/components/ApplicationSummaryCard/ApplicationSummaryCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".summary-cards-wrapper{\n    display: flex;\n    gap:0.2rem;\n}\n\n.application-card{\n    width: 100%;\n    background-color: aqua;\n    padding:0.1rem 4rem;\n}\n\n.single-card-description{\n    min-height:200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
