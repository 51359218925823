import './SelectionWithButtons.css'

const SelectionWithButtons = ({
    title,
    order = "horizontal",
    selected,
    setSelected,
    options 
}) => {
    return (
        <div>
            <span>{title}</span>
            <hr/>
            <div style={{ flexDirection: order == "horizontal" ? "row" : "column" }} className="buttons-selection-wrapper">
                {options.map((option, index) => (
                    <div
                        key={index}
                        onClick={()=>setSelected(index)}
                        className={`button-selection${index == selected ? " active" : ""}`}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SelectionWithButtons