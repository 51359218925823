// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soft-obs-wrapper{
    display: flex;
    flex-direction: column;
}

.soft-obs-overview-wrapper{
    display:flex;
}

.tall-rectangle{
    color:white;
    padding:4rem 1rem;
    text-align: center;
    vertical-align: middle;
}

.slider-wrapper{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    min-height: 230px;
    gap:0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/CloudMigrationAdvisor/InfrAssessmentSoftwareObsolescence/InfrAssessmentSoftwareObsolescence.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,UAAU;AACd","sourcesContent":[".soft-obs-wrapper{\n    display: flex;\n    flex-direction: column;\n}\n\n.soft-obs-overview-wrapper{\n    display:flex;\n}\n\n.tall-rectangle{\n    color:white;\n    padding:4rem 1rem;\n    text-align: center;\n    vertical-align: middle;\n}\n\n.slider-wrapper{\n    padding: 0.5rem;\n    display: flex;\n    flex-direction: column;\n    min-height: 230px;\n    gap:0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
