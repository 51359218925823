import React from 'react';
import { Container } from 'react-bootstrap';
import './Summary.css';

const Summary = ({ data, setData }) => {
  return (
    <Container>
      <div>
          <textarea
            className="contextItem"
            value={data}
            onChange={(e)=>setData(e.target.value)}
            cols={150}
            rows={18}
          />
            {/* // <span className="contextTitle">{item.title}:</span> {item.content} */}
      </div>
    </Container>
  );
};

export default Summary;
