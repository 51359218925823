// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-wrapper {
    background-color: #D9D9D9;
    border-radius: 33px;
    width: 80%;
    margin: auto;
    padding-top: 1rem;
    margin-top: 1.75rem;
}

.details-wrapper h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 2rem;
}

.details-wrapper hr{
    background-color: #F5F5F5;
    margin:0px;
    color:#D9D9D9;
    border-top:2px solid;
}

.nav-tabs .nav-item button {
    border-radius: 33px 33px 0px 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color:#808080;
    
}

.nav-tabs .nav-item button.active {
    color: #007680;
    background-color: #F5F5F5;
 
    border-color: #F5F5F5;
}

.tab-wrapper{
    background-color: #F5F5F5;
}


.infa-data-wrapper{
    margin-top: 3rem;
    margin-left: 4rem;
     width: 93%;
}`, "",{"version":3,"sources":["webpack://./src/components/CMA/InfrastructureAssessment/InfrastructureAssessment.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;;AAEjB;;AAEA;IACI,cAAc;IACd,yBAAyB;;IAEzB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,gBAAgB;IAChB,iBAAiB;KAChB,UAAU;AACf","sourcesContent":[".details-wrapper {\n    background-color: #D9D9D9;\n    border-radius: 33px;\n    width: 80%;\n    margin: auto;\n    padding-top: 1rem;\n    margin-top: 1.75rem;\n}\n\n.details-wrapper h1 {\n    font-size: 24px;\n    font-weight: 400;\n    line-height: 22px;\n    letter-spacing: 0px;\n    text-align: left;\n    padding-left: 2rem;\n}\n\n.details-wrapper hr{\n    background-color: #F5F5F5;\n    margin:0px;\n    color:#D9D9D9;\n    border-top:2px solid;\n}\n\n.nav-tabs .nav-item button {\n    border-radius: 33px 33px 0px 0px;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: center;\n    color:#808080;\n    \n}\n\n.nav-tabs .nav-item button.active {\n    color: #007680;\n    background-color: #F5F5F5;\n \n    border-color: #F5F5F5;\n}\n\n.tab-wrapper{\n    background-color: #F5F5F5;\n}\n\n\n.infa-data-wrapper{\n    margin-top: 3rem;\n    margin-left: 4rem;\n     width: 93%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
