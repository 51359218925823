import React, { useState, useEffect } from 'react'
import UploadForm from '../../../components/UploadForm/UploadForm'
import AssessmentList from '../../../components/Forms/AssessmentList'
import { getContextAssessment, putContextAssessment, uploadContext } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { useMyGlobalContext } from '../../../global/globalContext'
import toast, { Toaster } from "react-hot-toast";

const AssessmentFeatureForm = () => {
  const [currentId, setCurrentId] = useState(undefined)
  const [assessmentData, setAssessmentData] = useState([]);
  const { globalState } = useMyGlobalContext()
  const init = async () => {
    try {
      const response = await getContextAssessment(globalState.projectId, globalState.assessmentId)
      const data = response.data
      setCurrentId(data?.id)
      const assessment = [{
        category: "General",
        elements: [
          {
            title: "Company Name",
            value: data.Company_Name ? data.Company_Name : ""
          },
          {
            title: "Industry",
            value: data.Industry ? data.Industry : ""
          },
          {
            title: "Geographical Scope",
            value: data.Geographical_Scope ? data.Geographical_Scope : ""
          },
          {
            title: "Core Business",
            value: data.Core_Business ? data.Core_Business : ""
          },
          {
            title: "Personnel Size",
            value: data.Personnel_Size ? data.Personnel_Size : ""
          },
        ]
      },
      {
        category: "IT Related Information",
        elements: [
          {
            title: "IT Department Structure",
            value: data.IT_Department_Structure ? data.IT_Department_Structure : ""
          },
          {
            title: "Main Business Flows Related to Applications",
            value: data.Main_Business_Flows_Related_to_Applications ? data.Main_Business_Flows_Related_to_Applications : ""
          },
          {
            title: "Past Problems with IT Infrastructure",
            value: data.Past_Problems_with_IT_Infrastructure ? data.Past_Problems_with_IT_Infrastructure : ""
          },
          {
            title: "Performance Optimization Goals",
            value: data.Performance_Optimization_Goals ? data.Performance_Optimization_Goals : ""
          },
          {
            title: "Experience with Cloud Computing",
            value: data.Experience_with_Cloud_Computing ? data.Experience_with_Cloud_Computing : ""
          },
          {
            title: "IT Fleet Evolution Strategy",
            value: data.IT_Fleet_Evolution_Strategy ? data.IT_Fleet_Evolution_Strategy : ""
          },
        ]
      }
      ]
      setAssessmentData(assessment)
    } catch (error) {

    }
  }

  const handleSave = async () => {
    try {
      const body = {
        id: currentId,
        "Company_Name": assessmentData[0].elements[0].value,
        "Industry": assessmentData[0].elements[1].value,
        "Geographical_Scope": assessmentData[0].elements[2].value,
        "Core_Business": assessmentData[0].elements[3].value,
        "Personnel_Size": assessmentData[0].elements[4].value,
        "IT_Department_Structure": assessmentData[1].elements[0].value,
        "Main_Business_Flows_Related_to_Applications": assessmentData[1].elements[1].value,
        "Past_Problems_with_IT_Infrastructure": assessmentData[1].elements[2].value,
        "Performance_Optimization_Goals": assessmentData[1].elements[3].value,
        "Experience_with_Cloud_Computing": assessmentData[1].elements[4].value,
        "IT_Fleet_Evolution_Strategy": assessmentData[1].elements[5].value
      }
      await putContextAssessment(globalState.projectId, globalState.assessmentId, body)
      toast.success("Context saved successfully!")
    } catch (error) {
      toast.error("Failed to save")
    }
  }

  useEffect(() => {
    init()
  }, [])

  const handleUpload = async (formData) =>{
    try{
        await uploadContext(globalState.projectId, globalState.assessmentId, formData)
    }catch(error){
      console.log(error)
    }
  }
  
  return (
    <div>
      <UploadForm handleUpload={(formData)=>handleUpload(formData)}  confirmUpload={false}/>
      <AssessmentList data={assessmentData} setData={setAssessmentData} generable={false} />
      <button className='assessment-button' style={{float:"right"}} onClick={() => handleSave()}>
        Save
      </button>
    </div>
  )
}

export default AssessmentFeatureForm