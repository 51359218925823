import UploadForm from "../../../components/UploadForm/UploadForm"

const ApplicationDataIngestion = () => {
    return (
        <div>
            <UploadForm downloadable={true} downloadUrl="" confirmUpload={false} showFiles={false} />
        </div>
    )
}

export default ApplicationDataIngestion