import "./WizardNavigationButtons.css"

const WizardNavigationButtons = ({
    previousUrl,
    nextUrl,
    previousLabel = "Previous Section",
    nextLabel = "Confirm and go to the next section",
    nextFunction = () => {},
    previousFunction = () => {}
}) => {
    return (
        <div className="navigation-buttons-wrapper my-3">
            {previousUrl &&
                <button className="previous-section" onClick={()=>previousFunction(previousUrl)}>
                    {previousLabel}
                </button>
            }
            {nextUrl &&
                <button className="next-section" onClick={()=>nextFunction(nextUrl)}>
                    {nextLabel}
                </button>
            }
        </div>
    )
}

export default WizardNavigationButtons