// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    margin-top: 25px;
    margin-bottom: 60px;
}

.icon-link {
    text-decoration: none;
    color: black;
    margin-left: 10px;
}

.icon-link:hover {
    color: #26890d;
}

.form-control:focus,
.form-select:focus {
    border-color: #26890d !important;
    box-shadow: 0 0 0 0.25rem rgba(38, 137, 13, .25) !important;
}
 .saveBtn {
    background-color: #26890d !important;
    border-color: #26890d !important;
    font-weight: bold !important;
    transition: none !important;
    border-radius: unset !important;
}
.cancelButton {
    background-color: #aeaeae !important;
    border-color: #aeaeae !important;
    font-weight: bold !important;
    transition: none !important;
    border-radius: unset !important;
}

 .saveBtn:hover {
    background-color: #43b02a !important;
    border-color: #43b02a !important;
    color: #000 !important;
}

.form-label {
    font-weight: bold;
    margin-top: 6px;
}

.chatButton {
    position:fixed;
    bottom:60px;
    right: 20px;
    border-radius: 50% !important;
    height:60px;
    width:60px;
}

.chatButton i {
    font-size: 30px;
}

.improveButCont{
    display: flex;
    justify-content: space-between;
}

 `, "",{"version":3,"sources":["webpack://./src/pages/ApplicationBuild/ApplicationInitiation.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,gCAAgC;IAChC,2DAA2D;AAC/D;CACC;IACG,oCAAoC;IACpC,gCAAgC;IAChC,4BAA4B;IAC5B,2BAA2B;IAC3B,+BAA+B;AACnC;AACA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,4BAA4B;IAC5B,2BAA2B;IAC3B,+BAA+B;AACnC;;CAEC;IACG,oCAAoC;IACpC,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,WAAW;IACX,6BAA6B;IAC7B,WAAW;IACX,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".container {\n    margin-top: 25px;\n    margin-bottom: 60px;\n}\n\n.icon-link {\n    text-decoration: none;\n    color: black;\n    margin-left: 10px;\n}\n\n.icon-link:hover {\n    color: #26890d;\n}\n\n.form-control:focus,\n.form-select:focus {\n    border-color: #26890d !important;\n    box-shadow: 0 0 0 0.25rem rgba(38, 137, 13, .25) !important;\n}\n .saveBtn {\n    background-color: #26890d !important;\n    border-color: #26890d !important;\n    font-weight: bold !important;\n    transition: none !important;\n    border-radius: unset !important;\n}\n.cancelButton {\n    background-color: #aeaeae !important;\n    border-color: #aeaeae !important;\n    font-weight: bold !important;\n    transition: none !important;\n    border-radius: unset !important;\n}\n\n .saveBtn:hover {\n    background-color: #43b02a !important;\n    border-color: #43b02a !important;\n    color: #000 !important;\n}\n\n.form-label {\n    font-weight: bold;\n    margin-top: 6px;\n}\n\n.chatButton {\n    position:fixed;\n    bottom:60px;\n    right: 20px;\n    border-radius: 50% !important;\n    height:60px;\n    width:60px;\n}\n\n.chatButton i {\n    font-size: 30px;\n}\n\n.improveButCont{\n    display: flex;\n    justify-content: space-between;\n}\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
