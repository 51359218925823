// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TechnologyStack.css */
.technology-stack {
    border-radius: 8px;
    padding: 20px;
    margin: auto;
  }
  
  .technology-stack h2 {
    color: #02351C;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .stack-section {
    margin-bottom: 20px;
  }
  
  .stack-section h3 {
    color: #86BC25;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .stack-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .stack-item p {
    margin: 0;
    font-weight: 500;
  }
  
  .stack-item span {
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 4px;
  }
  
  .fully-supported {
    color: #4CAF50; /* Green for fully supported */
  }
  
  .out-of-support {
    color: #F44336; /* Red for out of support */
  }
  
  /* Additional styling for different levels of support could be added here */
`, "",{"version":3,"sources":["webpack://./src/components/TechnologyStack/TechnologyStack.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,cAAc,EAAE,8BAA8B;EAChD;;EAEA;IACE,cAAc,EAAE,2BAA2B;EAC7C;;EAEA,2EAA2E","sourcesContent":["/* TechnologyStack.css */\n.technology-stack {\n    border-radius: 8px;\n    padding: 20px;\n    margin: auto;\n  }\n  \n  .technology-stack h2 {\n    color: #02351C;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .stack-section {\n    margin-bottom: 20px;\n  }\n  \n  .stack-section h3 {\n    color: #86BC25;\n    font-size: 16px;\n    margin-bottom: 10px;\n  }\n  \n  .stack-item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n    border-radius: 4px;\n    margin-bottom: 5px;\n  }\n  \n  .stack-item p {\n    margin: 0;\n    font-weight: 500;\n  }\n  \n  .stack-item span {\n    font-size: 14px;\n    padding: 2px 8px;\n    border-radius: 4px;\n  }\n  \n  .fully-supported {\n    color: #4CAF50; /* Green for fully supported */\n  }\n  \n  .out-of-support {\n    color: #F44336; /* Red for out of support */\n  }\n  \n  /* Additional styling for different levels of support could be added here */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
