// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infr-ingestion-wrapper .nav-link.active{
    background-color: #86BC25;
}

.infr-ingestion-wrapper .nav-link{
    background-color: #BBBCBC;
    color: #53565A;
}

select{
    padding:0rem 0.5rem;
    border-radius: 15px;
    border-width: 0.15rem;
    border-color:#43B02A;
    background-color:#43B02A;
    color:white
}

select:focus{
    border-color:#2C5234;
}

select option:checked{
    background-color:#2C5234;
}

select option:hover {
    background-color: initial; /* Reset the background color on hover */
    color: initial; /* Reset the text color on hover */
  }
`, "",{"version":3,"sources":["webpack://./src/pages/CloudMigrationAdvisor/InfrAssessmentDataIngestion/InfrAssessmentDataIngestion.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,qBAAqB;IACrB,oBAAoB;IACpB,wBAAwB;IACxB;AACJ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB,EAAE,wCAAwC;IACnE,cAAc,EAAE,kCAAkC;EACpD","sourcesContent":[".infr-ingestion-wrapper .nav-link.active{\n    background-color: #86BC25;\n}\n\n.infr-ingestion-wrapper .nav-link{\n    background-color: #BBBCBC;\n    color: #53565A;\n}\n\nselect{\n    padding:0rem 0.5rem;\n    border-radius: 15px;\n    border-width: 0.15rem;\n    border-color:#43B02A;\n    background-color:#43B02A;\n    color:white\n}\n\nselect:focus{\n    border-color:#2C5234;\n}\n\nselect option:checked{\n    background-color:#2C5234;\n}\n\nselect option:hover {\n    background-color: initial; /* Reset the background color on hover */\n    color: initial; /* Reset the text color on hover */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
