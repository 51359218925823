// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.inline {
    display: flex;
    flex-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/ApplicationBuild/ApplicationCodeSkeleton.css"],"names":[],"mappings":";AACA;IAEI,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\n.inline {\n    display: -webkit-flex;\n    display: flex;\n    flex-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
