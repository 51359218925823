import { generateInfrastractureSummary, generateInfrastracturePoints, getInfraPoints } from "../../../services/CloudMigrationAdvisor/InfrastructureAssessment"
import { generateContextSummary, getSummary, putSummary } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { useEffect, useState } from "react"
import Summary from "../../../components/Summary/Summary"
import { useMyGlobalContext } from "../../../global/globalContext"
import toast from "react-hot-toast"

const InfrAssessmentSummary = () => {
    const [summaryData, setSummaryData] = useState()
    const [points, setPoints] = useState([])
    const { globalState } = useMyGlobalContext()
    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            const response = await getSummary(globalState.projectId, globalState.assessmentId)
            const response2 = await getInfraPoints(globalState.projectId, globalState.assessmentId)
            const data = response.data
            const data2 = response2.data
            setPoints(data2)
            setSummaryData(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleGenerate = async () => {
        try {
            await generateInfrastractureSummary(globalState.projectId, globalState.assessmentId)
            toast.success("Infrastructure Resume generation started!")
        } catch (error) {
            toast.error("Infrastructure Resume generate failed")
        }
    }

    const handleGeneratePoints = async () => {
        try {
            await generateInfrastracturePoints(globalState.projectId, globalState.assessmentId)
            toast.success("Infrastructure Critical Points generation started!")
        } catch (error) {
            toast.error("Infrastructure Critical Points generate failed")
        }
    }

    return (
        <div>
            
            <button className='assessment-button' onClick={() => handleGeneratePoints()}>
                Generate Points
            </button>
            <div>
                Critical Points
                <ul>
                    {points.map((point, index) => (
                        <li key={index}>
                            <span style={{ fontWeight: "bold" }}>{point.Title}:</span>
                            {point.Description}
                            <br />
                            <span style={{ fontWeight: "bold" }}>Migration Impact:</span>
                            {point.MigrationImpact}
                        </li>))}
                </ul>
            </div>
            <button className='assessment-button' onClick={() => handleGenerate()}>
                Generate
            </button>
            {summaryData &&
                <div style={{ display: 'flex' }}>
                    <Summary
                        data={summaryData.ITInfrastructureResume}
                        setData={(newContextResume) => setSummaryData({ ...summaryData, ITInfrastructureResume: newContextResume })}
                    />
                </div>
            }
            
        </div>
    )
}

export default InfrAssessmentSummary