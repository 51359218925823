// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table.css */
.table-container {
  margin-top: 20px;
  background-color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #dddddd;
}

tr.selectable:hover{
  background-color: #808080;
  cursor: pointer;
}

tr.selectable:hover td{
  border: 1px solid #808080;
}

th {
  background-color: #F9FAFB;
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/SimpleTable.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Table.css */\n.table-container {\n  margin-top: 20px;\n  background-color: white;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n}\n\nth,\ntd {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #dddddd;\n}\n\ntr.selectable:hover{\n  background-color: #808080;\n  cursor: pointer;\n}\n\ntr.selectable:hover td{\n  border: 1px solid #808080;\n}\n\nth {\n  background-color: #F9FAFB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
