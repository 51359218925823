import ApplicationSummaryCard from "../../../components/ApplicationSummaryCard/ApplicationSummaryCard"

const ApplicationSummaryCards = () => {
    return (
        <div className="summary-cards-wrapper">
            <div>
                side stuff
            </div>
            <div  style={{ width: "90%" }}>
                <ApplicationSummaryCard
                    title="Application 1"
                    description="This is just a description that allows the user to have a high-level understanding of how the application "
                />
            </div>
        </div>
    )
}

export default ApplicationSummaryCards