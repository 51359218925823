// TechnologyStack.js
import React from 'react';
import './TechnologyStack.css'; // Import the corresponding CSS

const TechnologyStack = ({ title, data }) => {
    return (
        <div className="technology-stack">
            <h2>{title}</h2>
            {data.map((section, index) => (
                <div className="stack-section" key={index}>
                    <h3>{section.category}</h3>
                    {section.items.map((item, itemIndex) => (
                        <div className="stack-item" key={itemIndex}>
                            <p>{item.name}</p>
                            <span className={item.support.toLowerCase().replace(/\s+/g, '-')}>{item.support}</span>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default TechnologyStack;
