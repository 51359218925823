// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.selectable:hover{
  cursor: pointer;
}

.table-header {
    background: #43b02a;
    color: white;
    font-weight: bold;
    border: 1px solid #000;
    padding: 8px;
  }
  
  .table-cell {
    background: white;
    color: black;
    border: 1px solid #000;
    padding: 8px;
  }

  .editable-table {
    border-collapse: collapse; 
    width: 100%;
  }
  
.form-control {
    border: none !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }`, "",{"version":3,"sources":["webpack://./src/components/Tables/EditableTable.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;AAEF;IACI,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,sBAAsB;EACxB","sourcesContent":["tr.selectable:hover{\n  cursor: pointer;\n}\n\n.table-header {\n    background: #43b02a;\n    color: white;\n    font-weight: bold;\n    border: 1px solid #000;\n    padding: 8px;\n  }\n  \n  .table-cell {\n    background: white;\n    color: black;\n    border: 1px solid #000;\n    padding: 8px;\n  }\n\n  .editable-table {\n    border-collapse: collapse; \n    width: 100%;\n  }\n  \n.form-control {\n    border: none !important;\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
