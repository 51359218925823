// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardWrap {
    position: relative !important;
    margin: 10px !important;
    width: 235px !important;
    height: 100px !important;
    background-color: #26890D !important;
    cursor: pointer !important;
    border: 2px solid black !important;
}

.cardWrap.selected {
    background-color: #0D3B00 !important;
  }

.cardWrap:hover {
    opacity: 85% !important;
}
  
.iconContainer {
    position: absolute;
    bottom: 0;
    right: 0;
}
  
.principalContent{
    color: white;
    font-weight: bold;
    margin: 3px;
}

.csvUploadIcon{
    position: absolute;
    top: 0;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/PrincipalComponent/principalComponent.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;IACxB,oCAAoC;IACpC,0BAA0B;IAC1B,kCAAkC;AACtC;;AAEA;IACI,oCAAoC;EACtC;;AAEF;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ","sourcesContent":[".cardWrap {\n    position: relative !important;\n    margin: 10px !important;\n    width: 235px !important;\n    height: 100px !important;\n    background-color: #26890D !important;\n    cursor: pointer !important;\n    border: 2px solid black !important;\n}\n\n.cardWrap.selected {\n    background-color: #0D3B00 !important;\n  }\n\n.cardWrap:hover {\n    opacity: 85% !important;\n}\n  \n.iconContainer {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n}\n  \n.principalContent{\n    color: white;\n    font-weight: bold;\n    margin: 3px;\n}\n\n.csvUploadIcon{\n    position: absolute;\n    top: 0;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
