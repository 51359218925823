import { useState, useEffect } from 'react'
import EditableTable from '../../../components/Tables/EditableTable'
import RobotPopover from '../../../components/Popovers/RobotPopover'
import { getInfraVirtualizationCluster } from '../../../services/CloudMigrationAdvisor/InfrastructureAssessment'

const InfrAssessmentClusterSaturation = () => {
    const [tableData, setTableData] = useState([])
    const [headers, setHeaders] = useState(["Cluster", "vCPU/CPU", "vRAM/RAM", "N of VM"])

    useEffect(()=>{
        init()
    },[])

    const init = async()=>{
        try{
            const response = await getInfraVirtualizationCluster(1,1)
            const data = response.data
            const mappedData = data.map(clusterData=>([
                clusterData.Name,
                clusterData.Number_of_VirtualCPU,
                clusterData.VirtualRAM,
                clusterData.Number_of_Virtual_Machines
            ]))
            setTableData(mappedData)
            console.log(mappedData)
        }catch(error){
            console.log(error)
        }
    }
    return (
        <div>
            <EditableTable data={tableData} setData={setTableData} headers={headers} />
            <RobotPopover
                text="From the analyses carried out, it emerged the presence of a very large main cluster that has a saturation that is not particularly critical with an average availability of resources. The other smaller clusters are also not of particular concern, ensuring average resource availability. Migration to the cloud is therefore recommended, but not extremely  "
                position="left"
            />
        </div>
    )
}

export default InfrAssessmentClusterSaturation