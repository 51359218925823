import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import WizardNavigationButtons from '../WizardNavigationButtons/WizardNavigationButtons';
import "./AssessmentMenu.css"
import { useMyGlobalContext } from '../../global/globalContext';

const AssessmentMenu = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const {globalState} = useMyGlobalContext()
    const [topSelection, setTopSelection] = useState("")
    const [midSelection, setMidSelection] = useState("")
    const [bottomSelection, setBottomSelection] = useState("")

    useEffect(()=>{
        if(!globalState.projectId || !globalState.assessmentId) navigate("/it-assessment")
    },[])

    const topMenuMapping = {
        "as-is-assesment": "AS-IS Assessment",
        "cloud-migration-strategy": "Cloud Migration Strategy",
        "feasiblity-study": "Feasibility Study"
    }

    const midMenuMapping = {
        "context-assessment-feature": "Context Assessment Feature",
        "infrastructure-assessment-feature": "Infrastructure Assessment Feature",
        "application-assessment-feature": "Application Assessment Feature",
        "assessment-resume": "Assessment Resume"
    }

    const bottomMenuMapping = {
        "context-form": "Context Form",
        "questions": "Questions generated by AI",
        "context-resume": "Context Resume",
        "infrastructure-context": "Infr. Context",
        "infrastructure-data-ingestion": "Infr. Data Ingestion",
        "host-obsolescence": "Host Obsolescence",
        // "cluster-saturation": "Cluster Saturation",
        "fault-tolerance": "Fault Tolerance",
        "software-data-ingestion": "Software Data Ingestion",
        "infrastructure-questions":"Infr. Questions",
        // "software-analysis": "Software Analysis",
        "software-obsolescence": "Software Obsolescence",
        "infrastructure-summary": "Summary",
        "application-context": "Application Context",
        "application-data-ingestion": "Application Data Ingestion",
        "application-summary-cards": "Application Summary Cards",
        "application-summary": "Summary",
        "res-test-1": "Res test 1",
        "res-test-2": "Res test 2"
    }

    const topMenu = ["as-is-assesment", "cloud-migration-strategy", "feasiblity-study"]

    const topToMidMapping = {
        "as-is-assesment": ["context-assessment-feature", "infrastructure-assessment-feature", "application-assessment-feature", "assessment-resume"]
    }

    const midToBottom = {
        "context-assessment-feature": ["context-form", "questions", "context-resume"],
        "infrastructure-assessment-feature": [
            "infrastructure-context",
            "infrastructure-questions",
            "infrastructure-data-ingestion",
            "host-obsolescence",
            // "cluster-saturation",
            "fault-tolerance",
            "software-data-ingestion",
            // "software-analysis",
            "software-obsolescence",
            "infrastructure-summary"
        ],
        "application-assessment-feature": [
            "application-context",
            "application-data-ingestion",
            "application-summary-cards",
            "application-summary"
        ],
        "assessment-resume": ["res-test-1", "res-test-2"]
    }

    useEffect(() => {
        const path = location.pathname.split("/")
        setTopSelection(path[4])
        setMidSelection(path[5])
        setBottomSelection(path[6])
    }, [location])

    const handleMenuClick = (level, selection) => {
        const path = location.pathname.split("/")
        const currentMid = path[5]
        const currentBottom = path[6]
        if (level == "top") {

        } else if (level == "mid") {
            if (selection == currentMid) return
            const newPath = `${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${selection}/${midToBottom[selection][0]}`
            navigate(newPath)
        } else if (level == "bottom") {
            if (selection == currentBottom) return
            const newPath = `${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/${selection}`
            navigate(newPath)
        }
    }

    const calculateNextSection = () => {
        if (!bottomSelection || !midSelection || !topSelection) return
        const path = location.pathname.split("/")
        //If there isn't next section return null
        if (
            topSelection == topMenu[topMenu.length - 1] &&
            midSelection == topToMidMapping[topSelection][topToMidMapping[topSelection].length - 1] &&
            bottomSelection == midToBottom[midSelection][midToBottom[midSelection].length - 1]
        ) {
            return null
        }
        if (bottomSelection != midToBottom[midSelection][midToBottom[midSelection].length - 1]) {
            const currentBottomIndex = midToBottom[midSelection].findIndex((el) => el == bottomSelection)
            const newPath = `${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/${midToBottom[midSelection][currentBottomIndex + 1]}`
            return newPath
        } else if (midSelection != topToMidMapping[topSelection][topToMidMapping[topSelection].length - 1]) {
            const currentMidIndex = topToMidMapping[topSelection].findIndex((el) => el == midSelection)
            const newPath = `${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${topToMidMapping[topSelection][currentMidIndex + 1]}/${midToBottom[topToMidMapping[topSelection][currentMidIndex + 1]][0]}`
            return newPath
        }
    }

    const calculatePreviousSection = () => {
        if (!bottomSelection || !midSelection || !topSelection) return
        const path = location.pathname.split("/")
        //If there isn't next section return null
        if (
            topSelection == topMenu[0] &&
            midSelection == topToMidMapping[topSelection][0] &&
            bottomSelection == midToBottom[midSelection][0]
        ) {
            return null
        }
        if (bottomSelection != midToBottom[midSelection][0]) {
            const currentBottomIndex = midToBottom[midSelection].findIndex((el) => el == bottomSelection)
            const newPath = `${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/${midToBottom[midSelection][currentBottomIndex - 1]}`
            return newPath
        } else if (midSelection != topToMidMapping[topSelection][0]) {
            const currentMidIndex = topToMidMapping[topSelection].findIndex((el) => el == midSelection)
            const newPath = `${path[0]}/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${topToMidMapping[topSelection][currentMidIndex - 1]}/${midToBottom[topToMidMapping[topSelection][currentMidIndex - 1]][0]}`
            return newPath
        }
    }

    return (
        <div className='menu-wrapper mt-3'>
            <div className='assessment-menu-row  mb-2'>
                {
                    topMenu.map((topMenu, index) => (
                        <div onClick={() => handleMenuClick()} key={index} className={`menu-item${topSelection == topMenu ? " selected" : ""}`}>
                            {topMenuMapping[topMenu]}
                        </div>
                    ))
                }
            </div>
            <div className='assessment-menu-row'>
                {
                    topToMidMapping[topSelection] && topToMidMapping[topSelection].map((midMenu, index) => (
                        <div onClick={() => handleMenuClick("mid", midMenu)} key={index} className={`menu-item${midSelection == midMenu ? " selected" : ""}`}>
                            {midMenuMapping[midMenu]}
                        </div>
                    ))
                }
            </div>
            <hr />
            <div className='assessment-menu-row  mb-3'>
                {
                    midToBottom[midSelection] && midToBottom[midSelection].map((bottomMenu, index) => (
                        <div onClick={() => handleMenuClick("bottom", bottomMenu)} key={index} className={`menu-item${bottomSelection == bottomMenu ? " selected" : ""}`}>
                            {bottomMenuMapping[bottomMenu]}
                        </div>
                    ))
                }
            </div>
            <Outlet />
            <WizardNavigationButtons
                previousUrl={calculatePreviousSection()}
                nextUrl={calculateNextSection()}
                nextFunction={(url) => navigate(url)}
                previousFunction={(url) => navigate(url)}
            />
        </div>
    )
}

export default AssessmentMenu