import Select from 'react-select'

const SelectForm = ({
    options,
    setSelectedOption
}) => {
    return (
        <Select
            options={options}
            onChange={(e) => setSelectedOption(e)}
            styles={{
                control: (styles,{isFocused}) => ({
                    ...styles, width: '300px',
                    boxShadow:"none",
                    borderColor:isFocused?"#86BC25":"",
                    ':hover': {
                        ...styles[':active'],
                        borderColor:"#86BC25"
                    },
                }),
                option: (styles, { isFocused }) => {
                    return {
                        ...styles,
                        backgroundColor: isFocused ? "#86BC25" : "#D0D0CE",
                        color: "black",
                        ':active': {
                            ...styles[':active'],
                            backgroundColor: isFocused ? "#43B02A" : "#D0D0CE"
                        },
                    };
                },

            }}
        />
    )
}

export default SelectForm