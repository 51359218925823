const DisplayInformation = ({
    title,
    data,
    columns = 1,
    bg = "white"
}) => {
    return (
        <div style={{ backgroundColor: bg }}>
            {title}
            <hr />
            <div>
                {
                    Object.entries(data).map((keyValuePair, index) => {
                        if(columns>1 && index%2==1){
                            return
                        }
                        return(<div className="row mb-2">
                            <div className="col-6">
                                {
                                    columns == 1 ?
                                        <>
                                            {keyValuePair[0]}
                                        </>
                                        : 
                                        <div>
                                            <span>{keyValuePair[0]}</span>
                                            <br/>
                                            <span>{keyValuePair[1].map((value,index)=>index>0?<><br/>{value}</>:value)}</span>
                                        </div>
                                }
                            </div>
                            <div className="col-6">
                                {
                                    columns == 1 ?
                                        <>
                                            {keyValuePair[1].map((value,index)=>index>0?`, ${value}`:value)}
                                        </>
                                        : Object.entries(data).length > index + 1 ?
                                        <div>
                                            {Object.entries(data)[index+1][0]}
                                            <br/>
                                            {Object.entries(data)[index+1][1].map((value,index)=>index>0?<><br/>{value}</>:value)}
                                        </div>
                                        :""
                                }
                            </div>
                        </div>
                    )})
                }
            </div>
        </div>
    )
}

export default DisplayInformation