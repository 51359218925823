import React, { useState, useEffect } from 'react'
import Summary from '../../../components/Summary/Summary'
import { generateContextSummary, getSummary, putSummary } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { useMyGlobalContext } from '../../../global/globalContext'
import toast from 'react-hot-toast'

const ContextResume = () => {
    const [summaryData, setSummaryData] = useState()
    const { globalState } = useMyGlobalContext()
    const init = async () => {
        try {
            const response = await getSummary(globalState.projectId, globalState.assessmentId)
            const data = response.data
            setSummaryData(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        init()
    }, [])

    const generateSummary = async () => {
        try {
            await generateContextSummary(globalState.projectId, globalState.assessmentId)
            toast.success("Generation Started!")
        } catch (error) {
            toast.error("Generation start failed!")
        }
    }

    const handleSave = async () => {
        try {
            console.log(summaryData)
            await putSummary(globalState.projectId, globalState.assessmentId, summaryData)
            toast.success("Resume saved successfully!")
        } catch (error) {
            toast.error("Resume saving failed!")
        }
    }

    return (
        <>
            <button className='assessment-button' onClick={() => generateSummary()}>
                Generate
            </button>

            {summaryData && <Summary data={summaryData.ContextResume} setData={(newContextResume) => setSummaryData({ ...summaryData, ContextResume: newContextResume })} />}
            <button className='assessment-button' style={{ float: "right" }} onClick={() => handleSave()}>
                Save
            </button>
        </>
    );
}

export default ContextResume