import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplications } from '../../services/applicationService';
import "./ApplicationList.css";
import useCurrentProject from '../../components/App/useCurrentProject';
import ApplicationBuildMenu from '../../components/ApplicationBuildMenu/ApplicationBuildMenu';
 import "./ApplicationRequirementDetail.css";
 import{  getProjects,  getProject,  updateProject,  createProject,} from '../../services/projectService';








const ApplicationProject = () => {
      const [applications, setApplications] = useState([]);
      const {currentProject} = useCurrentProject();

      const init = async () => {
        try {
            console.log('currentProject', currentProject)
            const applicationList = await getApplications(currentProject);

            setApplications(applicationList.data);

        } catch (error) {
            //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
            //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
        }
    }

    useEffect(() => {
        init();
    }, []);

    return (
      <>
     
        <div className='container'>
    <div className="d-sm-flex align-items-center justify-content-between mb-4 page-title">
              <h1 className="h2 mb-0 text-gray-800">Projects</h1>
              <div>
             {/*}<Button variant="primary" onClick={init} className="addReq-btn">
              <i className="fas fa-sync fa-sm text-white-50"></i> Refresh
              </Button>
              &nbsp;&nbsp;{*/}
              <Link to={`/projects/${currentProject}/application-build/create-project` } className="btn btn-sm btn-primary createApp-btn"><i
                      className="fas fa-plus fa-sm text-white-50"></i> Create Project</Link>
                      </div>
          </div>
    <Table striped bordered hover>
        <thead>
          <tr>
            <th className="col-md-1">#</th>
            <th className="col-md-5">Project Name</th>
            <th className="col-md-5">Sector</th>
            <th className="col-md-1"></th>
          </tr>
        </thead>
        <tbody>
          { applications.map((application) => {
            return (<tr>
            <td>{application.id}</td>
            <td>{application.name}</td>
            <td>{application.sector}</td>
            <td>

            <Container fluid>

            <Row>
              <Col>
              <Link className="nav-link inline collapsed iconTab" to={`/projects/${currentProject}/application-build/${application.id}/initiation` } data-toggle="collapse" data-target="#collapseTwo"
                          aria-expanded="true" aria-controls="collapseTwo">
                          <i className="fas fa-edit"></i>
                         
                  </Link>
                </Col>
                </Row>
                </Container>
            </td>
          </tr>
            )
          })}
        </tbody>
      </Table>
  </div>
  </>
  );
  };

export default ApplicationProject;





 