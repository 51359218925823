// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documentForm{
    margin-top: 20px;
    margin-left: 200px;
    margin-right: 200px;
}

.formHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generateText{
  margin-right: 10px;
}
  
.docTitle {
    margin-top: 10px;
    font-size: 27px;
    margin-left: 50px;
    width: 600px;
}

.form-control{
  border: 1px solid green !important;
}

.form-control:hover{
  border-color: green;
  box-shadow: 0 0 5px rgba(0, 128, 0, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/pages/ApplicationBuild/document/ApplicationDocument.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,wCAAwC;AAC1C","sourcesContent":[".documentForm{\n    margin-top: 20px;\n    margin-left: 200px;\n    margin-right: 200px;\n}\n\n.formHeader{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.generateText{\n  margin-right: 10px;\n}\n  \n.docTitle {\n    margin-top: 10px;\n    font-size: 27px;\n    margin-left: 50px;\n    width: 600px;\n}\n\n.form-control{\n  border: 1px solid green !important;\n}\n\n.form-control:hover{\n  border-color: green;\n  box-shadow: 0 0 5px rgba(0, 128, 0, 0.7);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
